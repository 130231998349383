import { Icon } from "@fluentui/react";
import React, { useRef, useState } from "react";
import { Overlay, Popover } from "react-bootstrap";

export function FilterIcon({ column }: any) {
  const [show, setShow] = useState(false);
  const [target, setTarget] = useState(null);
  const ref = useRef(null);

  const handleClick = (event: any) => {
    event.preventDefault();
    setShow(!show);
    setTarget(event.target);
  };

  return (
    <span ref={ref} className="m-1" style={{ cursor: "pointer" }}>
      {column.filterValue ? (
        <Icon iconName="FilterSolid" onClick={handleClick} />
      ) : (
        <Icon iconName="Filter" onClick={handleClick} />
      )}
      <Overlay
        show={show}
        target={target}
        placement="bottom"
        container={ref}
        containerPadding={20}
        rootClose
        onHide={handleClick}
      >
        <Popover id="popover-contained">
          <Popover.Header as="h3">Filter</Popover.Header>
          <Popover.Body>{column.render("Filter")}</Popover.Body>
        </Popover>
      </Overlay>
    </span>
  );
}
