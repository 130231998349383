import { OrganisationState } from "../store/createStore";
import { getWrapper } from "./auth";
const backendEndpoint = process.env.REACT_APP_BACKEND_ENPOINT || "";

interface OrganisationResponse {
  [organisationId: string]: OrganisationState;
}

export const organisationsService = async (
  userStillAuthorised: any,
  handleOnIdle: any,
  setORGANISATIONS: any,
  periodStart: any,
  periodEnd: any,
  setPlaceholder?: any
): Promise<OrganisationResponse[]> => {
  const organisationsArr = await getWrapper(
    `${backendEndpoint}/organisations/usage/all?periodStart=${periodStart}&periodEnd=${periodEnd}`
  );
  if (organisationsArr.error) {
    console.log("error: ", organisationsArr.error);
    setORGANISATIONS([]);
    if (setPlaceholder) {
      setPlaceholder("Error Fetching Organisations from Flo");
    }
    // setError(organisationsArr.error);
    if (!userStillAuthorised(organisationsArr)) {
      console.log("handling logout");
      //logout
      handleOnIdle();
    }
    return [];
  }
  return organisationsArr;
};

export const organisationUsageService = async (
  userStillAuthorised: any,
  handleOnIdle: any,
  orgId: string
): Promise<any[]> => {
  const organisationsArr = await getWrapper(
    `${backendEndpoint}/organisations/usage/${orgId}`
  );
  if (organisationsArr.error) {
    console.log("error: ", organisationsArr.error);

    // setError(organisationsArr.error);
    if (!userStillAuthorised(organisationsArr)) {
      console.log("handling logout");
      //logout
      handleOnIdle();
    }
    return [];
  }
  return organisationsArr;
};

export const timezoneMap = (timezone: string): string => {
  if (timezone === "Europe/London") {
    return "London";
  }
  if (timezone === "America/New_York") {
    return "New York";
  }
  return timezone;
};
