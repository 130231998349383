import React from "react";
import { useMsal } from "@azure/msal-react";
import { Button } from "react-bootstrap";
import { handleLogout } from "../services/auth";
import { useStore } from "../store/createStore";

function SignInError() {
  const { instance } = useMsal();
  const setAuthError = useStore((state) => state.setAuthError);
  const setCurrentUser = useStore((state) => state.setCurrentUser);

  return (
    <div className="sign-in-error">
      <h2>Error</h2>
      <h6>There has been an issue signing in.</h6>
      <Button
        variant="secondary"
        className="ml-auto"
        onClick={async () => {
          handleLogout(instance, setCurrentUser);
          setAuthError(false);
        }}
      >
        Try again
      </Button>
    </div>
  );
}

export default SignInError;
