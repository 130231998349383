import React from "react";
import { Button, Modal } from "react-bootstrap";
import { Icon } from "@fluentui/react/lib/Icon";

function Timeout({ showIdle, handleCloseIdle }: any) {
  return (
    <>
      <Modal
        show={showIdle}
        onHide={handleCloseIdle}
        backdrop="static"
        size="sm"
        style={{ textAlign: "center" }}
      >
        <Modal.Header>
          <Modal.Title>Session Expired</Modal.Title>
        </Modal.Header>
        <Modal.Body className="timeout">
          <Icon
            iconName="SkypeCircleClock"
            style={{
              padding: "0px 0px 5px 0px",
              fontSize: "4em",
              color: "orange",
            }}
          />
          <p>Your session has expired, you may need to log back in.</p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            className="ml-auto"
            onClick={() => {
              handleCloseIdle();
            }}
          >
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default Timeout;
