import { Icon } from "@fluentui/react";
import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

export const StatusIcon = ({ status }: any) => {
  const renderTooltip = (props: any) => (
    <Tooltip id="button-tooltip" {...props}>
      {status === "active"
        ? "Active bundle"
        : status === "future"
        ? "Future bundle"
        : "Expired bundle"}
    </Tooltip>
  );

  const color =
    status === "active" ? "green" : status === "future" ? "blue" : "red";

  return (
    <>
      <OverlayTrigger
        placement="right"
        delay={{ show: 250, hide: 400 }}
        overlay={renderTooltip}
      >
        <Icon
          iconName="RadioBtnOn"
          style={{
            marginRight: "8px",
            color,
          }}
        />
      </OverlayTrigger>
    </>
  );
};
