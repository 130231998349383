import React, { useCallback, useEffect, useState } from "react";
import {
  getBundleUsage,
  getBundleUsageHistory,
} from "../../services/bundleUsageService";
import { Button, Col, Form, ListGroup, Row, Spinner } from "react-bootstrap";
import { Icon } from "@fluentui/react";
import { BundleUsage } from "../../interfaces/bundleUsage";
import { useStore } from "../../store/createStore";
import { userStillAuthorised } from "../../services/auth";
import Loading from "../Shared/Loading";
import Error from "../Shared/Error";
import { UsageTable } from "../Usage/UsageTable";
import { UKDateStringToDate } from "../Shared/utils";
import { Bundle } from "../../interfaces/bundle";
import moment from "moment";
import { updateBundleExpiry } from "../../services/bundlesService";

function BundleDetails({ bundle }: any) {
  const handleOnIdle = useStore((state) => state.handleOnIdle);
  const [bundleUsage, setBundleUsage] = useState<BundleUsage>();
  const [usageHistory, setUsageHistory] = useState<Bundle[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [showAllOrgs, setShowAllOrgs] = useState<boolean>(false);
  const [error, setError] = useState<string>("");
  const [searchAttempted, setSearchAttempted] = useState(false);
  const [editExpiryDropDownVisible, setEditExpiryDropDownVisible] =
    useState(false);
  const [updatedBundle, setUpdatedBundle] = useState<Bundle>(bundle);
  const [updateLoading, setUpdateLoading] = useState(false);

  console.log("bundle: ", bundle);

  const handleExpirySubmit = useCallback(async () => {
    setUpdateLoading(true);
    console.log("updating bundle: ", updatedBundle);
    const resp = await updateBundleExpiry({
      bundleId: updatedBundle.bundleId,
      expiryDate: UKDateStringToDate(updatedBundle.expiryDate).toISOString(),
    });
    console.log("resp: ", resp);
    window.location.reload();
    setUpdateLoading(false);
  }, [updatedBundle]);

  const isActive = useCallback(() => {
    if (bundle && bundle.active) {
      return bundle.active === 1;
    }
    return false;
  }, [bundle]);

  const isValidExpiry = useCallback(() => {
    if (
      UKDateStringToDate(bundle.startDate) <= new Date() &&
      new Date() < UKDateStringToDate(bundle.expiryDate)
    ) {
      return true;
    }
    return false;
  }, [bundle]);

  const isFuture = useCallback(() => {
    if (bundle && bundle.startDate) {
      if (UKDateStringToDate(bundle.startDate) > new Date()) {
        return true;
      }
    }
    return false;
  }, [bundle]);

  const getUsageHistory = useCallback(
    async (bundleId: number) => {
      setLoading(true);
      const resp = await getBundleUsageHistory(bundleId);
      console.log("resp:", resp);
      setSearchAttempted(true);
      if (resp.error) {
        setUsageHistory([]);
        setError("Error retrieving bundles");
        setLoading(false);
        if (!userStillAuthorised(resp)) {
          handleOnIdle();
        }
        return;
      }
      setUsageHistory(resp);
      setError("");
      setLoading(false);
    },
    [setLoading, handleOnIdle]
  );

  const getUsage = useCallback(async (id: number) => {
    const resp = await getBundleUsage(id);
    //console.log(resp);
    if (resp.error) {
      console.log("Error retrieving bundle usage", resp.error);
      return "No Bundle Usage found";
    } else if (resp) {
      console.log("Successfully retrieved bundle usage", resp);
      setBundleUsage(resp);
      return resp;
    } else {
      console.log("Error retreiving bundle usage", resp);
      return "No Bundle Usage found";
    }
  }, []);

  useEffect(() => {
    getUsage(bundle.bundleId);
    getUsageHistory(bundle.bundleId);
  }, [bundle, getUsage, getUsageHistory]);

  return (
    <div className="bundles">
      <div
        style={{ display: "flex", justifyContent: "center", padding: "15px" }}
      >
        {isActive() && !isFuture() && (
          <Icon
            iconName="RadioBtnOn"
            style={{ margin: "4px", marginRight: "8px", color: "green" }}
          />
        )}
        {!isActive() && isFuture() && (
          <Icon
            iconName="RadioBtnOn"
            style={{ margin: "4px", marginRight: "8px", color: "blue" }}
          />
        )}
        {!isActive() && !isFuture() && (
          <Icon
            iconName="RadioBtnOn"
            style={{ margin: "4px", marginRight: "8px", color: "red" }}
          />
        )}
        <h4>{bundle.bundleDescription}</h4>
      </div>
      <Row className="mb-3">
        <Col>
          <ListGroup>
            <ListGroup.Item>
              <div className="fw-bold">Organisations:</div>
              {(bundle.bundleOrganisations.length <= 3 || showAllOrgs) && (
                <>
                  <ListGroup variant="flush">
                    {bundle.bundleOrganisations.map((org: any) => {
                      return (
                        <>
                          <ListGroup.Item
                            style={{ margin: 0, padding: 0 }}
                            className="bg-transparent"
                            id={org.name}
                          >
                            <ListGroup variant="flush">
                              <ListGroup.Item>{org.name}</ListGroup.Item>
                            </ListGroup>
                          </ListGroup.Item>
                        </>
                      );
                    })}
                    {bundle.bundleOrganisations.length > 3 && (
                      <Button
                        size="sm"
                        variant="outline-secondary"
                        onClick={() => setShowAllOrgs(false)}
                      >
                        Show Less
                      </Button>
                    )}
                  </ListGroup>
                </>
              )}
              {bundle.bundleOrganisations.length > 3 && !showAllOrgs && (
                <ListGroup variant="flush">
                  {bundle.bundleOrganisations.slice(0, 3).map((org: any) => {
                    return (
                      <>
                        <ListGroup.Item
                          style={{ margin: 0, padding: 0 }}
                          className="bg-transparent"
                          id={org.name}
                        >
                          <ListGroup variant="flush">
                            <ListGroup.Item>{org.name}</ListGroup.Item>
                          </ListGroup>
                        </ListGroup.Item>
                      </>
                    );
                  })}
                  {bundle.bundleOrganisations.length > 3 && (
                    <Button
                      variant="outline-secondary"
                      size="sm"
                      onClick={() => setShowAllOrgs(true)}
                    >
                      Show All ({bundle.bundleOrganisations.length})
                    </Button>
                  )}
                </ListGroup>
              )}
            </ListGroup.Item>
          </ListGroup>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col>
          <ListGroup>
            <ListGroup.Item>
              <div className="fw-bold">Initial Balance:</div>
              {bundle.initialBalance || 0}
            </ListGroup.Item>
          </ListGroup>
        </Col>
        <Col>
          <ListGroup>
            <ListGroup.Item>
              <div className="fw-bold">Opening Balance:</div>
              {bundle.openingBalance || 0}
            </ListGroup.Item>
          </ListGroup>
        </Col>
        <Col>
          <ListGroup>
            <ListGroup.Item>
              <div className="fw-bold">Used Messages:</div>
              {bundleUsage &&
                bundle.initialBalance - bundleUsage.remainingBalance}
              {!bundleUsage && loading && (
                <div style={{ padding: "5px" }}>
                  <Spinner animation={"border"} />
                </div>
              )}
              {!bundleUsage && !loading && 0}
            </ListGroup.Item>
          </ListGroup>
        </Col>
        <Col>
          <ListGroup>
            <ListGroup.Item>
              <div className="fw-bold">Remaining Balance:</div>
              {bundle && bundle.remainingBalance && bundle.remainingBalance}
              {!bundle && loading && (
                <div style={{ padding: "5px" }}>
                  <Spinner animation={"border"} />
                </div>
              )}
              {!bundle && !loading && 0}
            </ListGroup.Item>
          </ListGroup>
        </Col>
      </Row>
      <Row>
        <Col>
          <ListGroup>
            <ListGroup.Item>
              <div className="fw-bold">Start:</div>
              {bundle &&
                bundle.startDate &&
                UKDateStringToDate(bundle.startDate).toLocaleDateString()}
            </ListGroup.Item>
          </ListGroup>
        </Col>
        <Col>
          <ListGroup>
            <ListGroup.Item
              variant={isValidExpiry() || isFuture() ? "" : "danger"}
            >
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div>
                  <div className="fw-bold">Expiry: </div>
                  {!editExpiryDropDownVisible && (
                    <span>
                      {UKDateStringToDate(
                        bundle.expiryDate
                      ).toLocaleDateString()}
                      <Icon
                        iconName="Edit"
                        style={{
                          margin: "4px",
                          color: "red",
                          fontSize: "0.8em",
                        }}
                        className="pointer"
                        onClick={() => setEditExpiryDropDownVisible(true)}
                      />
                    </span>
                  )}
                  {editExpiryDropDownVisible && !updateLoading && (
                    <div>
                      <Form>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          <Form.Label>Select Expiry Date: </Form.Label>
                          <Form.Control
                            type="date"
                            value={moment(
                              UKDateStringToDate(updatedBundle.expiryDate)
                            ).format("YYYY-MM-DD")}
                            onChange={(e) => {
                              console.log(
                                e.target.value,
                                new Date(e.target.value),
                                moment(new Date(e.target.value)).format(
                                  "DD-MM-YYYY"
                                )
                              );
                              setUpdatedBundle({
                                ...updatedBundle,
                                expiryDate: moment(
                                  new Date(e.target.value)
                                ).format("DD/MM/YYYY"),
                              });
                            }}
                            required={true}
                            placeholder="Expiry"
                          />
                        </Form.Group>
                        <Button
                          variant="primary"
                          size="sm"
                          style={{
                            marginRight: "4px",
                          }}
                          onClick={handleExpirySubmit}
                        >
                          Submit
                        </Button>
                        <Button
                          variant="secondary"
                          onClick={() => {
                            setEditExpiryDropDownVisible(false);
                            setUpdatedBundle(bundle);
                          }}
                          size="sm"
                        >
                          Cancel
                        </Button>
                      </Form>
                    </div>
                  )}
                  {editExpiryDropDownVisible && updateLoading && (
                    <div>...Updating</div>
                  )}
                </div>
              </div>
            </ListGroup.Item>
          </ListGroup>
        </Col>
        <Col>
          <ListGroup>
            <ListGroup.Item>
              <div className="fw-bold">Created:</div>
              {new Date(bundle.createdAt).toLocaleString()}
            </ListGroup.Item>
          </ListGroup>
        </Col>
        <Col>
          <ListGroup>
            <ListGroup.Item>
              <div className="fw-bold">Last Updated:</div>
              {new Date(bundle.updatedAt).toLocaleString()}
            </ListGroup.Item>
          </ListGroup>
        </Col>
        {/* <Col>
          <ListGroup>
            <ListGroup.Item>
              <div className="fw-bold">Timezone:</div>
              {bundle.timezone}
            </ListGroup.Item>
          </ListGroup>
        </Col> */}
      </Row>
      <div
        style={{
          padding: "30px 0px 0px 0px",
          textAlign: "center",
        }}
      >
        <div style={{ padding: "15px" }}>
          <h4>Usage History:</h4>
        </div>
        {usageHistory && usageHistory.length > 0 && !loading && !error && (
          <UsageTable usageHistory={usageHistory} />
        )}
        {usageHistory.length === 0 &&
          searchAttempted === true &&
          !error &&
          !loading && (
            <div className="loading">
              <h5 className="mb3">No Usage History Found</h5>
            </div>
          )}
        {loading && <Loading loadingText="fetching usage history..." />}
        {error && !loading && <Error errorText={error} />}
      </div>
    </div>
  );
}

export default BundleDetails;
