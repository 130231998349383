import React, { useMemo } from "react";
import { Table } from "react-bootstrap";
import {
  Column,
  useFilters,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import {
  //   dateSort,
  DefaultColumnFilter,
  //   filterExpiry,
  filterGreaterThan,
  //   SelectExpiredColumnFilter,
  SliderColumnFilter,
} from "../Shared/TableFilters";
import { FilterIcon } from "../Shared/FilterIcon";
import { Icon } from "@fluentui/react";
import { PaginationInput } from "../Shared/PaginationInput";
import { Invoice } from "../../interfaces/invoice";
import { GroupIcon } from "../Shared/GroupIcon";

export const InvoicesTable = ({ invoices }: any) => {
  const data: Array<any> = useMemo(() => {
    return invoices.map((invoice: Invoice) => {
      return {
        ...invoice,
      };
    });
  }, [invoices]);

  const columns: Array<Column> = useMemo(
    () => [
      {
        Header: "Description",
        accessor: "invoiceDescription", // accessor is the "key" in the data
        Filter: DefaultColumnFilter,
      },
      {
        Header: "Organisations",
        accessor: "invoiceOrganisations", // accessor is the "key" in the data
        canFilter: false,
        disableFilters: true,
      },
      {
        Header: "Purchase Order Number",
        accessor: "purchaseOrderNumber",
        Filter: DefaultColumnFilter,
      },
      {
        Header: "Invoice Number",
        accessor: "invoiceNumber",
        Filter: DefaultColumnFilter,
      },
      {
        Header: "Value",
        accessor: "value",
        Filter: SliderColumnFilter,
        filter: filterGreaterThan,
      },
      {
        Header: "Currency",
        accessor: "currency",
        canFilter: false,
        disableFilters: true,
      },
    ],
    []
  );

  const defaultColumn: any = React.useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter,
    }),
    []
  );

  const tableInstance: any = useTable(
    { columns, data, defaultColumn },
    useFilters,
    useSortBy,
    usePagination
  );
  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, page } =
    tableInstance;

  return (
    <div>
      <Table
        striped
        bordered
        variant="light"
        responsive
        className="table-hover"
        {...getTableProps()}
      >
        <thead>
          {
            // Loop over the header rows
            headerGroups.map((headerGroup: any, i: any) => {
              // Apply the header row props
              return (
                <tr {...headerGroup.getHeaderGroupProps()} key={i}>
                  {
                    // Loop over the headers in each row
                    headerGroup.headers.map((column: any) => (
                      // Apply the header cell props
                      <th
                        style={{
                          minWidth: "10vw",
                        }}
                        key={column.id}
                      >
                        {/* Add a sort direction indicator */}
                        <span
                          {...column.getHeaderProps(
                            column.getSortByToggleProps()
                          )}
                        >
                          {column.isSorted ? (
                            column.isSortedDesc ? (
                              <Icon className="m-1" iconName="SortDown" />
                            ) : (
                              <Icon className="m-1" iconName="SortUp" />
                            )
                          ) : // <Icon className="m-1" iconName="Sort" />
                          null}
                          {
                            // Render the header
                            column.render("Header")
                          }
                        </span>
                        <span>
                          {/* {column.canFilter ? column.render("Filter") : null} */}
                          {column.canFilter ? (
                            <FilterIcon column={column} />
                          ) : null}
                        </span>
                      </th>
                    ))
                  }
                </tr>
              );
            })
          }
        </thead>
        <tbody {...getTableBodyProps()}>
          {
            // Loop over the table rows
            page.map((row: any) => {
              // Prepare the row for display
              prepareRow(row);
              return (
                // Apply the row props
                <tr {...row.getRowProps()} key={row.id}>
                  {
                    // Loop over the rows cells
                    row.cells.map((cell: any) => {
                      // Apply the cell props
                      return (
                        <td
                          {...cell.getCellProps()}
                          key={`${cell.row.id} - ${cell.column.id}`}
                        >
                          {cell.column.Header !== "Organisations" &&
                            // Render the cell contents
                            cell.render("Cell")}
                          {cell.column.Header === "Organisations" &&
                            data[row.index].invoiceOrganisations &&
                            data[row.index].invoiceOrganisations.length > 1 && (
                              <>
                                <GroupIcon
                                  organisations={
                                    data[row.index].invoiceOrganisations
                                  }
                                />{" "}
                                Multiple
                              </>
                            )}
                          {cell.column.Header === "Organisations" &&
                            data[row.index].invoiceOrganisations &&
                            data[row.index].invoiceOrganisations.length ===
                              1 && (
                              <>
                                {data[row.index].invoiceOrganisations[0].name}
                              </>
                            )}
                        </td>
                      );
                    })
                  }
                </tr>
              );
            })
          }
        </tbody>
      </Table>
      <PaginationInput tableInstance={tableInstance} />
    </div>
  );
};
