import React from "react";
import { Button } from "react-bootstrap";
import { UKDateStringToDate } from "./utils";

// Filter components

export const Filter = ({ column }: any) => {
  return (
    <div style={{ marginTop: 5 }}>
      {column.canFilter && column.render("Filter")}
    </div>
  );
};

export function DefaultColumnFilter({
  column: { filterValue, preFilteredRows, setFilter },
}: any) {
  const count = preFilteredRows.length;

  return (
    <input
      value={filterValue || ""}
      onChange={(e) => {
        setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
      }}
      placeholder={`Search ${count} records...`}
    />
  );
}

export function NumberRangeColumnFilter({
  column: { filterValue = [], preFilteredRows, setFilter, id },
}: any) {
  const [min, max] = React.useMemo(() => {
    let min = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
    let max = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
    preFilteredRows.forEach((row: any) => {
      min = Math.min(row.values[id], min);
      max = Math.max(row.values[id], max);
    });
    return [min, max];
  }, [id, preFilteredRows]);

  return (
    <div
      style={{
        display: "flex",
      }}
    >
      <input
        value={filterValue[0] || ""}
        type="number"
        onChange={(e) => {
          const val = e.target.value;
          setFilter((old = []) => [
            val ? parseInt(val, 10) : undefined,
            old[1],
          ]);
        }}
        placeholder={`Min (${min})`}
        style={{
          width: "70px",
          marginRight: "0.5rem",
        }}
      />
      to
      <input
        value={filterValue[1] || ""}
        type="number"
        onChange={(e) => {
          const val = e.target.value;
          setFilter((old = []) => [
            old[0],
            val ? parseInt(val, 10) : undefined,
          ]);
        }}
        placeholder={`Max (${max})`}
        style={{
          width: "70px",
          marginLeft: "0.5rem",
        }}
      />
    </div>
  );
}

export function SliderColumnFilter({
  column: { filterValue, setFilter, preFilteredRows, id },
}: any) {
  // Calculate the min and max
  // using the preFilteredRows

  const [min, max] = React.useMemo(() => {
    let min = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
    let max = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
    preFilteredRows.forEach((row: any) => {
      min = Math.min(row.values[id], min);
      max = Math.max(row.values[id], max);
    });
    return [min, max];
  }, [id, preFilteredRows]);

  return (
    <>
      <input
        type="range"
        min={min}
        max={max}
        value={filterValue || min}
        onChange={(e) => {
          setFilter(parseInt(e.target.value, 10));
        }}
      />
      <Button
        variant="outline-secondary"
        size="sm"
        className="m-1"
        onClick={() => setFilter(undefined)}
      >
        Off
      </Button>
    </>
  );
}

export function SelectColumnFilter({
  column: { filterValue, setFilter, preFilteredRows, id },
}: any) {
  // Calculate the options for filtering
  // using the preFilteredRows
  const options = React.useMemo(() => {
    const options = new Set<any>();
    preFilteredRows.forEach((row: any) => {
      options.add(row.values[id]);
    });
    return [...options.values()];
  }, [id, preFilteredRows]);

  // Render a multi-select box
  return (
    <select
      value={filterValue}
      onChange={(e) => {
        setFilter(e.target.value || undefined);
      }}
    >
      <option value="">All</option>
      {options.map((option, i) => (
        <option key={i} value={option}>
          {option}
        </option>
      ))}
    </select>
  );
}

export function SelectExpiredColumnFilter({
  column: { filterValue, setFilter, preFilteredRows, id },
}: any) {
  // Render a multi-select box
  return (
    <select
      value={filterValue}
      onChange={(e) => {
        setFilter(e.target.value || undefined);
      }}
    >
      <option value="">All</option>
      <option value="expired">Expired</option>
      <option value="active">Active</option>
      <option value="n/a">N/A</option>
    </select>
  );
}

// Custom Filter Funtions:

export function filterGreaterThan(rows: any, id: any, filterValue: any) {
  return rows.filter((row: any) => {
    const rowValue = row.values[id];
    return rowValue >= filterValue;
  });
}

export const filterExpiry = (rows: any, id: any, filterValue: any) => {
  console.log(filterValue);
  return rows.filter((row: any) => {
    if (filterValue === "n/a") {
      return row.values[id] === "N/A";
    }
    if (filterValue === "active") {
      return new Date(row.values[id]) >= new Date();
    }
    if (filterValue === "expired") {
      console.log(new Date(row.values[id]), new Date());
      return new Date(row.values[id]) < new Date();
    }
    return true;
  });
};

export const dateSort = (rowA: any, rowB: any, id: any, desc: any) => {
  let a = UKDateStringToDate(rowA.values[`${id}`]);
  let b = UKDateStringToDate(rowB.values[`${id}`]);
  if (a < b) {
    return -1;
  } else {
    return 1;
  }
};

export function SelectRemainingBalanceFilter({
  column: { filterValue, setFilter, preFilteredRows, id },
}: any) {
  // Render a multi-select box
  return (
    <select
      value={filterValue}
      onChange={(e) => {
        setFilter(e.target.value || undefined);
      }}
    >
      <option value="">All</option>
      <option value="negative">Negative</option>
      <option value="projected">{"Projected < 0 in 30 days"}</option>
    </select>
  );
}

export const filterRemainingBalance = (
  rows: any,
  id: any,
  filterValue: any
) => {
  console.log(filterValue);
  return rows.filter((row: any) => {
    if (filterValue === "negative") {
      return Number(row.values[id]) < 0;
    }
    if (filterValue === "projected") {
      console.log(
        "projection: ",
        row.values,
        row.values["recentUsageAverage"],
        row.values["remainingBalance"],
        row.values["recentUsageAverage"] >= row.values["remainingBalance"]
      );
      return row.values["recentUsageAverage"] >= row.values["remainingBalance"];
    }
    return true;
  });
};
