import React, { useCallback, useMemo, useState } from "react";
import { Button, Form, FormGroup, Modal, Table } from "react-bootstrap";
import {
  Column,
  useFilters,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import { BundleState } from "../../store/createStore";
import {
  dateSort,
  DefaultColumnFilter,
  filterExpiry,
  // filterGreaterThan,
  SelectExpiredColumnFilter,
  // SliderColumnFilter,
} from "../Shared/TableFilters";
import { FilterIcon } from "../Shared/FilterIcon";
import { Icon } from "@fluentui/react";
import { alertCellStyling, alertRowStyling } from "../Shared/styling";
import BundleDetails from "./BundleDetails";
import { StatusIcon } from "../Shared/StatusIcon";
import { PaginationInput } from "../Shared/PaginationInput";
import { GroupIcon } from "../Shared/GroupIcon";

export const BundlesTable = ({
  bundles,
  type,
  setAttachedBundleIds,
  attachedBundleIds,
  attachedBundles,
  setAttachedBundles,
}: any) => {
  const [showBundleDetails, setShowBundleDetails] = useState(false);
  const [selectedBundle, setSelectedBundle] = useState<Partial<BundleState>>(
    {}
  );

  const handleBundleChecked = useCallback(
    (e: any) => {
      const checkedBundleDetails = JSON.parse(e.target.value);
      console.log(e.target.checked);
      if (e.target.checked) {
        setAttachedBundleIds([
          ...attachedBundleIds,
          checkedBundleDetails.bundleId,
        ]);
        setAttachedBundles([...attachedBundles, checkedBundleDetails]);
      } else {
        setAttachedBundleIds(
          attachedBundleIds.filter((bundleId: any) => {
            return bundleId !== checkedBundleDetails.bundleId;
          })
        );
        setAttachedBundles(
          attachedBundles.filter((bundle: any) => {
            return bundle.bundleId !== checkedBundleDetails.bundleId;
          })
        );
      }
    },
    [
      setAttachedBundleIds,
      attachedBundleIds,
      attachedBundles,
      setAttachedBundles,
    ]
  );

  const handleModalClose = () => {
    setShowBundleDetails(false);
  };

  const openBundleDetails = useCallback(
    (bundle: Partial<BundleState>) => {
      setSelectedBundle(bundle);
      setShowBundleDetails(true);
    },
    [setShowBundleDetails, setSelectedBundle]
  );

  const isFuture = useCallback((bundle: BundleState) => {
    if (new Date(bundle.startDate) > new Date()) {
      return true;
    }
    return false;
  }, []);

  const data: Array<any> = useMemo(() => {
    return bundles.map((bundle: BundleState) => {
      const active = !!bundle.active;
      const future = isFuture(bundle);
      let status: string;
      if (active && !future) {
        status = "active";
      } else if (!active && future) {
        status = "future";
      } else {
        status = "expired";
      }
      return {
        status,
        ...bundle,
        startDate: new Date(bundle.startDate).toLocaleDateString(),
        expiryDate: new Date(bundle.expiryDate).toLocaleDateString(),
        usageSum:
          Number(bundle.initialBalance) - Number(bundle.remainingBalance),
        select: type === "attachToInvoice" ? true : false,
      };
    });
  }, [bundles, isFuture, type]);

  console.log("data: ", data);

  const columns: Array<Column> = useMemo(
    () => [
      {
        Header: "Select",
        accessor: "select",
        canFilter: false,
        disableFilters: true,
      },
      {
        Header: "Status",
        accessor: "status", // accessor is the "key" in the data
        canFilter: false,
        disableFilters: true,
      },
      {
        Header: "Bundle ID",
        accessor: "bundleId", // accessor is the "key" in the data
        Filter: DefaultColumnFilter,
      },
      {
        Header: "Description",
        accessor: "bundleDescription",
        Filter: DefaultColumnFilter,
        // filter: filterGreaterThan,
      },
      {
        Header: "Organisations",
        accessor: "bundleOrganisations",
        canFilter: false,
        disableFilters: true,
      },
      {
        Header: "Initial Balance",
        accessor: "initialBalance",
        disableFilters: true,
        // Filter: SliderColumnFilter,
        // filter: filterGreaterThan,
      },
      {
        Header: "Opening Balance",
        accessor: "openingBalance",
        disableFilters: true,
        // Filter: SliderColumnFilter,
        // filter: filterGreaterThan,
      },
      // {
      //   Header: "Usage",
      //   accessor: "usageSum",
      //   Filter: SliderColumnFilter,
      //   filter: filterGreaterThan,
      // },
      {
        Header: "Remaining Balance",
        accessor: "remainingBalance",
        disableFilters: true,
        // Filter: SliderColumnFilter,
        // filter: filterGreaterThan,
      },
      // {
      //   Header: "Overage",
      //   accessor: "overage",
      //   Filter: SliderColumnFilter,
      //   filter: filterGreaterThan,
      // },
      {
        Header: "Start",
        accessor: "startDate",
        // Filter: SelectExpiredColumnFilter,
        // filter: filterExpiry,
        disableFilters: true,
        sortType: dateSort,
      },
      {
        Header: "Expiry",
        accessor: "expiryDate",
        Filter: SelectExpiredColumnFilter,
        filter: filterExpiry,
        sortType: dateSort,
      },
    ],
    []
  );
  // }
  const defaultColumn: any = React.useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter,
    }),
    []
  );
  let initialState;

  if (type === "attachToInvoice") {
    initialState = {
      hiddenColumns: ["status", "usageSum", "remainingBalance"],
    };
  } else if (type === "organisation") {
    initialState = { hiddenColumns: ["select"] };
  } else {
    initialState = {
      hiddenColumns: ["select", "usageSum", "remainingBalance"],
    };
  }

  const tableInstance: any = useTable(
    { columns, data, defaultColumn, initialState },
    useFilters,
    useSortBy,
    usePagination
  );
  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, page } =
    tableInstance;

  return (
    // apply the table props
    <div>
      {/* {
        // Select columns to show
        tableInstance.allColumns.map((column: any) => {
        return (
          <div key={column.id}>
            <label>
              <input type="checkbox" {...column.getToggleHiddenProps()} />{" "}
              {column.id}
            </label>
          </div>
        );
      })} */}
      <Table
        striped
        bordered
        variant="light"
        responsive
        className={type !== "invoice" ? "table-hover" : ""}
        {...getTableProps()}
      >
        <thead>
          {
            // Loop over the header rows
            headerGroups.map((headerGroup: any, i: any) => {
              // Apply the header row props
              return (
                <tr {...headerGroup.getHeaderGroupProps()} key={i}>
                  {
                    // Loop over the headers in each row
                    headerGroup.headers.map((column: any) => (
                      // Apply the header cell props
                      <th key={column.id}>
                        {/* Add a sort direction indicator */}
                        <span
                          {...column.getHeaderProps(
                            column.getSortByToggleProps()
                          )}
                        >
                          {column.isSorted ? (
                            column.isSortedDesc ? (
                              <Icon className="m-1" iconName="SortDown" />
                            ) : (
                              <Icon className="m-1" iconName="SortUp" />
                            )
                          ) : // <Icon className="m-1" iconName="Sort" />
                          null}
                          {
                            // Render the header
                            column.render("Header")
                          }
                        </span>
                        <span>
                          {/* {column.canFilter ? column.render("Filter") : null} */}
                          {column.canFilter ? (
                            <FilterIcon column={column} />
                          ) : null}
                        </span>
                      </th>
                    ))
                  }
                </tr>
              );
            })
          }
        </thead>
        {/* Apply the table body props */}
        <tbody {...getTableBodyProps()}>
          {!!data.length &&
            // Loop over the table rows
            page.map((row: any) => {
              // Prepare the row for display
              prepareRow(row);
              return (
                // Apply the row props
                <tr
                  {...row.getRowProps()}
                  onClick={() => openBundleDetails(data[row.index])}
                  key={row.id}
                  className={
                    type !== "organisation"
                      ? ""
                      : alertRowStyling(
                          row.values.expiryDate,
                          row.values.remainingBalance
                        )
                  }
                >
                  {
                    // Loop over the rows cells
                    row.cells.map((cell: any) => {
                      // Apply the cell props
                      return (
                        <td
                          {...cell.getCellProps()}
                          style={{
                            cursor:
                              type === "attachToInvoice"
                                ? "default"
                                : "pointer",
                            ...alertCellStyling(cell),
                          }}
                          key={`${cell.row.id} - ${cell.column.id}`}
                        >
                          {cell.column.Header !== "Status" &&
                            cell.column.Header !== "Select" &&
                            cell.column.Header !== "Organisations" &&
                            // Render the cell contents
                            cell.render("Cell")}
                          {cell.column.Header === "Status" && (
                            <StatusIcon status={data[row.index].status} />
                          )}
                          {cell.column.Header === "Organisations" &&
                            data[row.index].bundleOrganisations.length > 1 && (
                              <>
                                <GroupIcon
                                  organisations={
                                    data[row.index].bundleOrganisations
                                  }
                                />{" "}
                                Multiple
                              </>
                            )}
                          {cell.column.Header === "Organisations" &&
                            data[row.index].bundleOrganisations.length ===
                              1 && (
                              <>{data[row.index].bundleOrganisations[0].name}</>
                            )}
                          {cell.column.Header === "Select" && (
                            <FormGroup>
                              <Form.Check type="checkbox">
                                <Form.Check.Input
                                  onChange={handleBundleChecked}
                                  defaultChecked={attachedBundleIds.includes(
                                    data[row.index].bundleId
                                  )}
                                  style={{
                                    cursor: "pointer",
                                  }}
                                  value={JSON.stringify(data[row.index])}
                                ></Form.Check.Input>
                              </Form.Check>
                            </FormGroup>
                          )}
                        </td>
                      );
                    })
                  }
                </tr>
              );
            })}
          {!data.length && <tr>No bundles data</tr>}
        </tbody>
      </Table>
      <PaginationInput tableInstance={tableInstance} />
      {type !== "attachToInvoice" && (
        <Modal
          show={showBundleDetails}
          onHide={handleModalClose}
          size="xl"
          backdrop="static"
        >
          {/* <Modal.Header closeButton>
              <Modal.Title>Attach Invoice to Bundles</Modal.Title>
            </Modal.Header> */}
          <Modal.Body>
            <BundleDetails bundle={selectedBundle} />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleModalClose}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </div>
  );
};
