import React, { useCallback, useState } from "react";
import { Button, Form, Spinner } from "react-bootstrap";
import { inviteUser, userStillAuthorised } from "../services/auth";

function InviteUser({ handleOnIdle }: any) {
  const [email, setEmail] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);
  const successAnimation = useCallback(() => {
    setLoading(false);
    setSuccess(true);
    setTimeout(() => {
      console.log("in timeout");
      setSuccess(false);
    }, 1500);
  }, []);
  const handleSubmit = async (e: any) => {
    e.preventDefault();
    setLoading(true);
    console.log("email to submit: ", email);
    const resp = await inviteUser(email);
    console.log("resp:", resp);
    if (resp.error) {
      if (!userStillAuthorised(resp)) {
        console.log("handling logout");
        //logout
        handleOnIdle();
      }
    }
    successAnimation();
  };
  return (
    <div className="bundles">
      <h2>Invite User:</h2>
      <Form onSubmit={handleSubmit}>
        <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Label>Email Address: </Form.Label>
          <Form.Control
            type="text"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required={true}
            placeholder="Enter email address of user"
          />
        </Form.Group>
        {!loading && !success && (
          <Button variant="primary" type="submit">
            Invite
          </Button>
        )}
        {loading && !success && (
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        )}
        {success && (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="40"
            height="40"
            fill="currentColor"
            className="bi bi-check"
            viewBox="0 0 16 16"
          >
            <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z" />
          </svg>
        )}
      </Form>
    </div>
  );
}

export default InviteUser;
