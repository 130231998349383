import React, { useCallback, useEffect } from "react";
import "./App.css";
import { GetBundles } from "./components/Bundle/GetBundles";
import { AddBundle } from "./components/Bundle/AddBundle";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { TopNav } from "./components/TopNav";
import { Home } from "./components/Home";
import { Container } from "react-bootstrap";
import { AddInvoice } from "./components/Invoice/AddInvoice";
import {
  // AuthenticatedTemplate,
  // UnauthenticatedTemplate,
  useIsAuthenticated,
  useMsal,
} from "@azure/msal-react";
import SignIn from "./auth/SignIn";
import InviteUser from "./auth/InviteUser";
import { loginRequest } from "./auth/authConfig";
import { backendAuthentication, handleLogout } from "./services/auth";
import Loading from "./components/Shared/Loading";
import SignInError from "./auth/SignInError";
import { useIdleTimer } from "react-idle-timer";
import Timeout from "./auth/Timeout";
import BundleDetails from "./components/Bundle/BundleDetails";
import { GetInvoices } from "./components/Invoice/GetInvoices";
// import { Bundle } from "./components/Bundle/Bundle";
import { useStore } from "./store/createStore";
import { OrganisationDetails } from "./components/Organisation/OrganisationDetails";
import { Footer } from "./components/Footer";

function App() {
  const { instance, accounts } = useMsal();
  const userIdle = useStore((state) => state.userIdle);
  const setUserIdle = useStore((state) => state.setUserIdle);
  const handleOnIdle = useStore((state) => state.handleOnIdle);
  const currentUser = useStore((state) => state.user);
  const setCurrentUser = useStore((state) => state.setCurrentUser);
  const authError = useStore((state) => state.authError);
  const setAuthError = useStore((state) => state.setAuthError);

  // isAuthenticated is an msal hook which tracks the user's microsfot login status
  const isAuthenticated = useIsAuthenticated();

  // handle closing of session timeout modal
  const handleCloseIdle = () => {
    setUserIdle(false);
    localStorage.removeItem("user");
    handleLogout(instance, setCurrentUser);
  };

  // Track user inactivity
  useIdleTimer({
    timeout: 1000 * 60 * 10, // 10 minutes
    onIdle: handleOnIdle,
    debounce: 500,
  });

  // Handles getting JWT from the backend
  const GetToken = useCallback(async () => {
    try {
      // Get user object from local storage if it exists
      const userFromLocal = localStorage.getItem("user")
        ? JSON.parse(localStorage.getItem("user") || "{}")
        : "";

      // If we have a user object that includes isActive property set true
      // Then we want to set the current user property and return
      if (!currentUser && userFromLocal && userFromLocal.isActive) {
        console.log("user already set in local storage");
        return setCurrentUser(userFromLocal);
      }

      //TODO: confirm reqs for auto log out
      // If we have a user object that includes isActive property set true
      // Then we want to log the user out, this is to handle the user forcing a refresh on the page
      // (i.e. We need to log them out of there microsoft account rather than allowing them to obtain a new JWT)
      // if (userFromLocal && !userFromLocal.isActive) {
      //   localStorage.removeItem("user");
      //   // Do not get new token, log user out
      //   return handleLogout(instance, setCurrentUser);
      // }

      // Get microsoft token silently
      const request = {
        ...loginRequest,
        account: accounts[0],
      };
      const oauthResponse = await instance.acquireTokenSilent(request);

      // Get JWT from backend
      const backendAuthResponse = await backendAuthentication(
        oauthResponse.accessToken
      );
      if (backendAuthResponse.error) {
        // Set Auth error to direct user to error screen
        setAuthError(true);
        return setCurrentUser("");
      }
      // Set user object, which includes JWT
      localStorage.setItem(
        "user",
        JSON.stringify({ ...backendAuthResponse, isActive: true })
      );
      return setCurrentUser(backendAuthResponse);
    } catch (e: any) {
      console.log("error obtaining token: ", e);
      // If there is an error obtaining token we should auto-log-out the user
      return handleLogout(instance, setCurrentUser, true);
    }
  }, [accounts, instance, currentUser, setCurrentUser, setAuthError]);

  useEffect(() => {
    if (isAuthenticated && !currentUser && !userIdle) {
      GetToken();
    }
  }, [
    isAuthenticated,
    instance,
    accounts,
    currentUser,
    GetToken,
    userIdle,
    authError,
  ]);

  return (
    <div className="App">
      <Router>
        {currentUser && !authError && (
          <>
            <TopNav />
            <Container className="page">
              <Switch>
                <Route path="/bundles/add">
                  <AddBundle />
                </Route>
                <Route path="/invoices/add">
                  <AddInvoice />
                </Route>
                <Route path="/invoices">
                  <GetInvoices />
                </Route>
                <Route path="/bundles/:id">
                  <BundleDetails />
                </Route>
                <Route path="/bundles">
                  <GetBundles title="Bundles: " type="searchBundles" />
                  {/* <Bundle /> */}
                </Route>
                <Route path="/organisations/:id">
                  <OrganisationDetails />
                </Route>
                <Route path="/invite">
                  <InviteUser />
                </Route>
                <Route path="/">
                  <Home />
                </Route>
              </Switch>
            </Container>
            <Footer />
          </>
        )}
        {!currentUser && isAuthenticated && !authError && (
          <Container className="page">
            <Route path="/">
              <Loading loadingText="Signing in..." />
            </Route>
          </Container>
        )}
        {!currentUser && !isAuthenticated && !authError && (
          <>
            <div className="sign-in-page">
              <Switch>
                <Route path="/">
                  <SignIn />
                </Route>
              </Switch>
            </div>
          </>
        )}
        {authError && (
          <Container className="page">
            <Switch>
              <Route path="/">
                <SignInError />
              </Route>
            </Switch>
          </Container>
        )}
        {userIdle && (
          <Timeout showIdle={true} handleCloseIdle={handleCloseIdle} />
        )}
      </Router>
    </div>
  );
}

export default App;
