import React, { useCallback, useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import Select from "react-select";
import { organisationsService } from "../../services/organisationsService";
import { userStillAuthorised } from "../../services/auth";
import { OrganisationState, useStore } from "../../store/createStore";

export const OrgSelect = ({ onChange, required }: any) => {
  const ORGANISATIONS = useStore((state) => state.organisations);
  const setORGANISATIONS = useStore((state) => state.setOrganisations);
  const handleOnIdle = useStore((state) => state.handleOnIdle);
  const [placeholder, setPlaceholder] = useState(
    !ORGANISATIONS.length ? "Fetching Organisations..." : "Select Organisations"
  );
  const periodStart = useStore((state) => state.billingPeriodStart);
  const periodEnd = useStore((state) => state.billingPeriodEnd);

  const getOrganisations = useCallback(async () => {
    try {
      setORGANISATIONS([]);
      const organisationsArr = await organisationsService(
        userStillAuthorised,
        handleOnIdle,
        setORGANISATIONS,
        periodStart,
        periodEnd,
        setPlaceholder
      );
      const orgsToSave: any[] = [];
      Object.entries(organisationsArr).map((obj) =>
        orgsToSave.push({ organisationId: obj[0], ...obj[1] })
      );
      setORGANISATIONS(orgsToSave);
      setPlaceholder("Select Organisations");
    } catch (e: any) {
      console.log("error: ", e);
      setORGANISATIONS([]);
      setPlaceholder("Error Fetching Organisations from Flo");
      // setError(organisationsArr.error);
      if (!userStillAuthorised(e.message)) {
        console.log("handling logout");
        //logout
        handleOnIdle();
      }
      return;
    }
  }, [handleOnIdle, setORGANISATIONS, periodStart, periodEnd]);

  useEffect(() => {
    getOrganisations();
  }, [getOrganisations]);

  const orgCustomTheme = (theme: any) => {
    return {
      ...theme,
      colors: {
        ...theme.colors,
        primary25: "#cbd4ff",
        //primary: 'green',
      },
    };
  };

  const orgCustomStyles = {
    option: (provided: any, state: any) => {
      return { ...provided, color: "black" };
    },
    placeholder: (provided: any, state: any) => {
      return { ...provided, textAlign: "left" };
    },
  };

  return (
    <div>
      <Form.Group className="mb-3" controlId="formBasicEmail">
        {/* <Form.Label>Select Organisations: </Form.Label> */}
        <Select
          theme={orgCustomTheme}
          styles={orgCustomStyles}
          isMulti
          isSearchable
          placeholder={placeholder}
          aria-label="Select Organisations"
          options={
            !!ORGANISATIONS.length
              ? ORGANISATIONS.map((organisation: OrganisationState) => ({
                  value: organisation.organisationId,
                  label: organisation.name,
                }))
              : []
          }
          onChange={onChange}
        ></Select>
      </Form.Group>
    </div>
  );
};
