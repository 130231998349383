import React from "react";
import { Icon } from "@fluentui/react";
import { CSVLink } from "react-csv";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";

export const DownloadCSV = ({ data, filename }: any) => {
  const renderTooltip = (props: any) => (
    <Tooltip id="button-tooltip" {...props}>
      Dowload as CSV
    </Tooltip>
  );
  return (
    <OverlayTrigger
      placement="right"
      delay={{ show: 250, hide: 400 }}
      overlay={renderTooltip}
    >
      <CSVLink
        data={data}
        style={{
          cursor: "pointer",
          textDecoration: "none",
        }}
        filename={filename}
      >
        <Button variant="outline-primary" size="sm">
          <Icon iconName="DownloadDocument" />
        </Button>
      </CSVLink>
    </OverlayTrigger>
  );
};
