import React, { useCallback, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { Invoice } from "../../interfaces/invoice";
import { userStillAuthorised } from "../../services/auth";
import { getInvoices } from "../../services/invoiceService";
import Error from "../Shared/Error";
import Loading from "../Shared/Loading";
import { OrgSelect } from "../Shared/OrgSelect";
import { useStore } from "../../store/createStore";
import { InvoicesTable } from "./InvoicesTable";

export const GetInvoices = () => {
  const ORGANISATIONS = useStore((state) => state.organisations);
  const handleOnIdle = useStore((state) => state.handleOnIdle);
  const [invoices, setInvoices] = useState<Invoice[]>([]);
  // const [limit, setLimit] = useState<number>();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>("");
  const [orgChoice, setOrgChoice] = useState<string[]>([]);
  const [searchAttempted, setSearchAttempted] = useState(false);
  
  const get = useCallback(
    async (e) => {
      e.preventDefault();
      setLoading(true);
      const resp = await getInvoices(
        orgChoice,
      );
      // const resp = await getInvoices();
      console.log("resp:", resp);
      setSearchAttempted(true);
      if (resp.error) {
        setInvoices([]);
        setError("Error retrieving bundles");
        setLoading(false);
        if (!userStillAuthorised(resp)) {
          console.log("handling logout");
          //logout
          handleOnIdle();
        }
        return;
      }
      const invoicesWithOrgs = resp.map((invoice: any) => {
        const invoiceOrganisations = invoice.organisationIds
          .split(", ")
          .map(
            (orgId: any) =>
              ORGANISATIONS.filter((org) => org.organisationId === orgId)[0]
          );
        return {
          ...invoice,
          invoiceOrganisations,
        };
      });
      setInvoices(invoicesWithOrgs);
      setError("");
      setLoading(false);
    },
    [
      /*limit,*/ setLoading,
      handleOnIdle,
      orgChoice,
      ORGANISATIONS,
    ]
  );

  const handleOrgSelect = useCallback(
    async (e) => {
      await setOrgChoice(e.map((organisation: any) => organisation.value));
      console.log(orgChoice);
    },
    [setOrgChoice, orgChoice]
  );

  return (
    <div className="bundles">
      <h2>Search Invoices</h2>
      <Form className="mb-3">
        <OrgSelect
          onChange={handleOrgSelect}
          handleOnIdle={handleOnIdle}
          required={true}
        />
        <Button onClick={get} disabled={!ORGANISATIONS.length || loading}>
          Search
        </Button>
      </Form>
      {invoices.length === 0 && searchAttempted === true && !error && !loading && (
        <div className="loading">
          <h5 className="mb3">No invoices found matching search criteria</h5>
        </div>
      )}
      {invoices && invoices.length > 0 && !loading && !error && (
        <InvoicesTable invoices={invoices} />
      )}
      {loading && <Loading loadingText="searching for invoices..." />}
      {error && !loading && <Error errorText={error} />}
    </div>
  );
};
