import React, { useCallback, useEffect, useState } from "react";
import { Col, ListGroup, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { userStillAuthorised } from "../../services/auth";
import { OrganisationState, useStore } from "../../store/createStore";
import Loading from "../Shared/Loading";
import Error from "../Shared/Error";
import {
  organisationsService,
  organisationUsageService,
  // timezoneMap,
} from "../../services/organisationsService";
import { BundlesTable } from "../Bundle/BundlesTable";
import { UsageTable } from "../Usage/UsageTable";
import { Icon } from "@fluentui/react";
import {
  LineChart,
  Line,
  // CartesianGrid,
  // XAxis,
  // YAxis,
  Tooltip,
  // Legend,
  ResponsiveContainer,
} from "recharts";
// import { OrganisationsTable } from "./OrganisationsTable";

export const OrganisationDetails = () => {
  const ORGANISATIONS = useStore((state) => state.organisations);
  const setORGANISATIONS = useStore((state) => state.setOrganisations);
  const handleOnIdle = useStore((state) => state.handleOnIdle);
  const periodStart = useStore((state) => state.billingPeriodStart);
  const periodEnd = useStore((state) => state.billingPeriodEnd);
  const resetPeriod = useStore((state) => state.resetBillingPeriod);
  const { id }: any = useParams();
  const [selectedOrg, setSelectedOrg] = useState<OrganisationState>();
  const [orgUsage, setOrgUsage] = useState<any>();
  const [orgUsageForGraph, setOrgUsageForGraph] = useState<any>();
  const [loading, setLoading] = useState<boolean>(false);
  const [usageLoading, setUsageLoading] = useState<boolean>(false);
  const [showUsage, setShowUsage] = useState<boolean>(false);
  const [showBundles, setShowBundles] = useState<boolean>(false);
  const [error, setError] = useState<string>("");
  const [searchAttempted, setSearchAttempted] = useState(false);
  const [usageSearchAttempted, setUsageSearchAttempted] = useState(false);

  const getOrganisations = useCallback(async () => {
    try {
      setLoading(true);
      setSearchAttempted(true);
      resetPeriod();
      const organisationsArr = await organisationsService(
        userStillAuthorised,
        handleOnIdle,
        setORGANISATIONS,
        periodStart,
        periodEnd
      );
      const orgsToSave: any[] = [];
      Object.entries(organisationsArr).map((obj) =>
        orgsToSave.push({ organisationId: obj[0], ...obj[1] })
      );
      setORGANISATIONS(orgsToSave);
      setLoading(false);
    } catch (e: any) {
      setORGANISATIONS([]);
      if (!userStillAuthorised(e.message)) {
        handleOnIdle();
      }
      setLoading(false);
      setError(e);
      return;
    }
  }, [handleOnIdle, setORGANISATIONS, periodStart, periodEnd, resetPeriod]);

  const getUsage = useCallback(async () => {
    setUsageLoading(true);
    try {
      setUsageSearchAttempted(true);
      const orgUsage = await organisationUsageService(
        userStillAuthorised,
        handleOnIdle,
        id
      );
      console.log(orgUsage);
      setOrgUsage(orgUsage);
      setOrgUsageForGraph(
        orgUsage
          // .slice(orgUsage.length - 10)
          .map((usage: any) => {
            return {
              ...usage,
              periodStart: new Date(usage.periodStart),
              usageTotal: usage.usageOut + usage.usageIn,
            };
          })
          .reverse()
      );
      setUsageLoading(false);
    } catch (e: any) {
      setOrgUsage([]);
      setOrgUsageForGraph([]);
      if (!userStillAuthorised(e.message)) {
        handleOnIdle();
      }
      setUsageLoading(false);
      setError(e);
      return;
    }
  }, [setUsageLoading, id, setError, handleOnIdle, setOrgUsage]);

  useEffect(() => {
    getOrganisations();
  }, [getOrganisations]);

  useEffect(() => {
    if (ORGANISATIONS.length) {
      setSelectedOrg(
        ORGANISATIONS.filter((org) => org.organisationId === id)[0]
      );
      getUsage();
    }
  }, [ORGANISATIONS, id, getUsage]);

  const CustomTooltip = ({ active, payload, label }: any) => {
    console.log(active, JSON.stringify(payload), label);
    if (active && payload && payload.length) {
      return (
        <div style={{ padding: "10px", backgroundColor: "rgba(0,0,175,0.17)" }}>
          <p style={{ margin: 0, padding: 0 }}>{`${new Date(
            payload[0].payload.periodStart
          ).toLocaleDateString("en-GB")}`}</p>
          <p
            style={{ margin: 0, padding: 0 }}
          >{`usage in: ${payload[0].payload.usageIn}`}</p>
          <p
            style={{ margin: 0, padding: 0 }}
          >{`usage out: ${payload[0].payload.usageOut}`}</p>
        </div>
      );
    }

    return null;
  };

  return (
    <div>
      <h2 className="mt-3">{selectedOrg?.name}</h2>
      <div style={{ padding: "10px", marginTop: "15px", marginBottom: "15px" }}>
        {orgUsage && orgUsage.length > 0 && !usageLoading && (
          <div style={{ textAlign: "center" }}>
            <ResponsiveContainer aspect={10}>
              <LineChart
                data={orgUsageForGraph}
                margin={{ top: 5, right: 5, left: 5, bottom: 5 }}
              >
                <Line
                  type="monotone"
                  dataKey="usageTotal"
                  stroke="#8884d8"
                  strokeWidth={1}
                  dot={false}
                />
                {/* <Line
                  type="monotone"
                  dataKey="usageOut"
                  stroke="#82ca9d"
                  strokeWidth={1}
                  dot={false}
                /> */}
                <Tooltip
                  content={<CustomTooltip />}
                  wrapperStyle={{ outline: "none" }}
                />
              </LineChart>
            </ResponsiveContainer>
          </div>
        )}
      </div>
      {selectedOrg && (
        <>
          <div>
            {/* <Row md={3}> - ORG DETAILS
              <Col>
                <ListGroup style={{ margin: "15px" }}>
                  <ListGroup.Item
                    style={{ fontWeight: "bold", color: "black" }}
                  >
                    Organisation Type
                  </ListGroup.Item>
                  <ListGroup.Item>{selectedOrg.type}</ListGroup.Item>
                </ListGroup>
              </Col>
              <Col>
                <ListGroup style={{ margin: "15px" }}>
                  <ListGroup.Item
                    style={{ fontWeight: "bold", color: "black" }}
                  >
                    Country Code:
                  </ListGroup.Item>
                  <ListGroup.Item>{selectedOrg.countryCode}</ListGroup.Item>
                </ListGroup>
              </Col>
              <Col>
                <ListGroup style={{ margin: "15px" }}>
                  <ListGroup.Item
                    style={{ fontWeight: "bold", color: "black" }}
                  >
                    Timezone:
                  </ListGroup.Item>
                  <ListGroup.Item>
                    {timezoneMap(selectedOrg.timeZone)}
                  </ListGroup.Item>
                </ListGroup>
              </Col>
            </Row> */}
            <Row md={4}>
              <Col>
                <ListGroup style={{ margin: "15px" }}>
                  <ListGroup.Item
                    style={{ fontWeight: "bold", color: "black" }}
                  >
                    Overall Balance:
                  </ListGroup.Item>
                  <ListGroup.Item
                    className={
                      selectedOrg.overallRemainingBalance > 0
                        ? "positive"
                        : "negative"
                    }
                  >
                    {selectedOrg.overallRemainingBalance}
                  </ListGroup.Item>
                </ListGroup>
              </Col>
              <Col>
                <ListGroup style={{ margin: "15px" }}>
                  <ListGroup.Item
                    style={{ fontWeight: "bold", color: "black" }}
                  >
                    3 Month Average Usage:
                  </ListGroup.Item>
                  <ListGroup.Item
                    className={
                      Math.round(selectedOrg.recentUsageAverage) >=
                      selectedOrg.overallRemainingBalance
                        ? "negative"
                        : "positive"
                    }
                  >
                    {Math.round(selectedOrg.recentUsageAverage)}
                  </ListGroup.Item>
                </ListGroup>
              </Col>
              <Col>
                <ListGroup style={{ margin: "15px" }}>
                  <ListGroup.Item
                    style={{ fontWeight: "bold", color: "black" }}
                  >
                    Bundle Latest Expiry:
                  </ListGroup.Item>
                  <ListGroup.Item
                    className={
                      selectedOrg.overallExpiry !== "N/A" &&
                      new Date(selectedOrg.overallExpiry) > new Date()
                        ? "positive"
                        : "negative"
                    }
                  >
                    {selectedOrg.overallExpiry === "N/A"
                      ? selectedOrg.overallExpiry
                      : new Date(
                          selectedOrg.overallExpiry
                        ).toLocaleDateString()}
                  </ListGroup.Item>
                </ListGroup>
              </Col>
              <Col>
                <ListGroup style={{ margin: "15px" }}>
                  <ListGroup.Item
                    style={{ fontWeight: "bold", color: "black" }}
                  >
                    License Expiry:
                  </ListGroup.Item>
                  <ListGroup.Item
                    className={
                      selectedOrg.licenseExpiry &&
                      selectedOrg.licenseExpiry !== "N/A"
                        ? "positive"
                        : "negative"
                    }
                  >
                    {selectedOrg.licenseExpiry &&
                    selectedOrg.licenseExpiry !== "N/A"
                      ? new Date(selectedOrg.licenseExpiry).toLocaleDateString()
                      : "No License"}
                  </ListGroup.Item>
                </ListGroup>
              </Col>
            </Row>
          </div>
          <div
            className="container mt-3"
            style={{ backgroundColor: "white", padding: "5px" }}
          >
            {!showUsage && (
              <div
                className="container mt-3"
                style={{ cursor: "pointer" }}
                onClick={() => setShowUsage(true)}
              >
                <h5>Usage History</h5>
                <Icon iconName="ChevronDown" style={{ marginRight: "8px" }} />
              </div>
            )}
            {showUsage && (
              <>
                <div
                  className="container mt-3"
                  style={{ cursor: "pointer" }}
                  onClick={() => setShowUsage(false)}
                >
                  <h5>Usage History</h5>
                  <Icon iconName="ChevronUp" style={{ marginRight: "8px" }} />
                </div>
                <div className="container"></div>
                <div>
                  {orgUsage && orgUsage.length > 0 && !usageLoading && (
                    <div style={{ textAlign: "center" }}>
                      <UsageTable usageHistory={orgUsage} />
                      {/* <OrganisationsTable organisations={[selectedOrg]} /> */}
                    </div>
                  )}
                  {usageLoading && (
                    <Loading loadingText="searching for usage..." />
                  )}
                  {orgUsage &&
                    orgUsage.length === 0 &&
                    usageSearchAttempted === true &&
                    !usageLoading && (
                      <div className="loading">
                        <h5 className="mb3">No Usage Found</h5>
                      </div>
                    )}
                </div>
              </>
            )}
          </div>
          <div
            className="container mt-3"
            style={{ backgroundColor: "white", padding: "5px" }}
          >
            {!showBundles && (
              <div
                className="container mt-3"
                style={{ cursor: "pointer" }}
                onClick={() => setShowBundles(true)}
              >
                <h5>All Bundles</h5>
                <Icon iconName="ChevronDown" style={{ marginRight: "8px" }} />
              </div>
            )}
            {showBundles && (
              <>
                <div
                  className="container mt-3"
                  style={{ cursor: "pointer", marginBottom: "30px" }}
                  onClick={() => setShowBundles(false)}
                >
                  <h5>All Bundles</h5>
                  <Icon iconName="ChevronUp" style={{ marginRight: "8px" }} />
                </div>
                <div className="container">
                  {selectedOrg.bundles &&
                    selectedOrg.bundles.length > 0 &&
                    !loading &&
                    !error && (
                      <>
                        <BundlesTable
                          bundles={selectedOrg.bundles}
                          type="organisation"
                        />
                      </>
                    )}

                  {selectedOrg.bundles.length === 0 &&
                    searchAttempted === true &&
                    !error &&
                    !loading && (
                      <div className="loading">
                        <h5 className="mb3">No Bundles Found</h5>
                      </div>
                    )}

                  {loading && (
                    <Loading loadingText="searching for bundles..." />
                  )}

                  {error && !loading && <Error errorText={error} />}
                </div>
              </>
            )}
          </div>
        </>
      )}
    </div>
  );
};
