import React from "react";
import { GetOrganisations } from "./Organisation/GetOrganisations";

export const Home = () => {
  return (
    <>
      <div className="bundles">
        <GetOrganisations />
      </div>
    </>
  );
};
