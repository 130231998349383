import React from "react";
import ghLogo from "../GH.svg";

export const Footer = () => {
  return (
    <div className="footer">
      <a href="https://pshealth.co.uk/" target="_blank" rel="noreferrer">
        <img
          width="800"
          height="45vh"
          src={ghLogo}
          alt="Generated Health"
          style={{ padding: "5px" }}
        />
      </a>
    </div>
  );
};
