import { Bundle } from "../interfaces/bundle";
import { getWrapper, patchWrapper, postWrapper } from "./auth";
const backendEndpoint = process.env.REACT_APP_BACKEND_ENPOINT || "";

export const addBundle = async (bundle: Partial<Bundle>) => {
  try {
    return await postWrapper(`${backendEndpoint}/bundles`, bundle);
  } catch (e: any) {
    console.log(e);
    return { error: e.message };
  }
};


export const updateBundleExpiry = async (bundle: Partial<Bundle>) => {
  try {
    return await patchWrapper(`${backendEndpoint}/bundles/expiry`, bundle);
  } catch (e: any) {
    console.log(e);
    return { error: e.message };
  }
};

export const getBundles = async (orgChoice?: string[], limit?: number) => {
  try {
    console.log("Org Choice: ", orgChoice);
    const reducer = (previousValue: any, currentValue: any) =>
      String(previousValue) + "|" + String(currentValue);
    const url = limit
      ? `${backendEndpoint}/bundles?limit=${limit}`
      : `${backendEndpoint}/bundles`;
    const params = orgChoice
      ? { orgChoiceString: orgChoice.reduce(reducer) }
      : {};
    const resp = await getWrapper(url, {
      params,
    });
    console.log(resp);
    if (resp.error) return resp;
    return resp;
  } catch (e: any) {
    console.log(e);
    return { error: e.message };
  }
};

export const getBundlesWithOrgs = async (
  orgChoice: string[],
  bundleDescription?: string,
  limit?: number
) => {
  try {
    console.log("Org Choice: ", orgChoice);
    const reducer = (previousValue: any, currentValue: any) =>
      String(previousValue) + "|" + String(currentValue);
    const url = limit
      ? `${backendEndpoint}/bundleswithorgs?limit=${limit}`
      : `${backendEndpoint}/bundleswithorgs`;
    // const params = orgChoice.length
    //   ? { orgChoiceString: orgChoice.reduce(reducer) }
    //   : {};
    const params: any = {};
    if (orgChoice.length) {
      params.orgChoiceString = orgChoice.reduce(reducer);
    }
    if (bundleDescription) {
      params.bundleDescription = bundleDescription;
    }
    const resp = await getWrapper(url, {
      params,
      // paramsSerializer: params => {
      //   return qs.stringify(params)
      // }
    });
    console.log(resp);
    if (resp.error) return resp;
    return resp;
  } catch (e: any) {
    console.log(e);
    return { error: e.message };
  }
};
