import { Icon } from "@fluentui/react";
import React from "react";
import { ListGroup, OverlayTrigger, Tooltip } from "react-bootstrap";

export const GroupIcon = ({ organisations }: any) => {
  const renderTooltip = (props: any) => (
    <Tooltip id="org-tooltip" {...props}>
      {organisations ? (
        <ListGroup variant="flush">
          {organisations.map((org: any) => {
            return (
              <ListGroup.Item
                style={{ margin: 0, padding: 0, color: "white" }}
                className="bg-transparent"
                id={org.name}
              >
                {org.name}
              </ListGroup.Item>
            );
          })}
        </ListGroup>
      ) : (
        "testtest"
      )}
    </Tooltip>
  );
  return (
    <OverlayTrigger
      key="top"
      placement="right"
      delay={{ show: 250, hide: 400 }}
      overlay={renderTooltip}
    >
      <Icon
        style={{
          margin: "4px 8px 4px 4px",
          color: "black",
          fontWeight: "bold",
        }}
        iconName="Group"
      />
    </OverlayTrigger>
  );
};
