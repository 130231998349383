import { Icon } from "@fluentui/react";
import React from "react";
import { Button } from "react-bootstrap";
import { DownloadCSV } from "./DownloadCSV";

export const PaginationInput = ({ tableInstance }: any) => {
  return (
    <div className="pagination">
      <div>
        <span className="m-1">
          <Button
            onClick={() => tableInstance.gotoPage(0)}
            disabled={!tableInstance.canPreviousPage}
            size="sm"
            variant="outline-secondary"
            className="m-1"
          >
            <Icon iconName="ChevronLeftSmall" style={{ fontSize: "0.9em" }} />
            <Icon iconName="ChevronLeftSmall" style={{ fontSize: "0.9em" }} />
          </Button>{" "}
          <Button
            onClick={() => tableInstance.previousPage()}
            disabled={!tableInstance.canPreviousPage}
            size="sm"
            variant="outline-secondary"
            className="m-1"
          >
            <Icon iconName="ChevronLeftSmall" style={{ fontSize: "0.9em" }} />
          </Button>{" "}
          <Button
            onClick={() => tableInstance.nextPage()}
            disabled={!tableInstance.canNextPage}
            size="sm"
            variant="outline-secondary"
            className="m-1"
          >
            <Icon iconName="ChevronRightSmall" style={{ fontSize: "0.9em" }} />
          </Button>{" "}
          <Button
            onClick={() => tableInstance.gotoPage(tableInstance.pageCount - 1)}
            disabled={!tableInstance.canNextPage}
            size="sm"
            variant="outline-secondary"
            className="m-1"
          >
            <Icon iconName="ChevronRightSmall" style={{ fontSize: "0.9em" }} />
            <Icon iconName="ChevronRightSmall" style={{ fontSize: "0.9em" }} />
          </Button>
          {"  "}
        </span>
        <span className="m-1">
          <DownloadCSV
            data={tableInstance.data}
            filename={`BundlesExtract_${new Date().toLocaleString()}.csv`}
          />
        </span>
      </div>
      <div>
        <span className="m-1">
          Page{" "}
          <strong>
            {tableInstance.state.pageIndex + 1} of{" "}
            {tableInstance.pageOptions.length}
          </strong>{" "}
          {/* | Go to page:{" "}
          <input
            type="number"
            defaultValue={tableInstance.state.pageIndex + 1}
            onChange={(e) => {
              const page = e.target.value ? Number(e.target.value) - 1 : 0;
              tableInstance.gotoPage(page);
            }}
            style={{ width: "80px" }}
          /> */}
          <select
            value={tableInstance.state.pageSize}
            onChange={(e) => {
              tableInstance.setPageSize(Number(e.target.value));
            }}
            className="m-1"
          >
            {[10, 25, 50, 100].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                Show {pageSize}
              </option>
            ))}
          </select>
        </span>
      </div>
    </div>
  );
};
