import React, { useCallback } from "react";
import { Button, Card, Modal } from "react-bootstrap";
import Loading from "./Loading";
import { useHistory } from "react-router";
import { Icon } from "@fluentui/react";

export const Confirmation = ({
  showConfirmation,
  setShowConfirmation,
  handleConfirmationClose,
  loading,
  loadingText,
  success,
  successText,
  error,
  setError,
  type,
}: any) => {
  const history = useHistory();
  const goToRoute = useCallback(
    (route: string) => {
      history.push(route);
    },
    [history]
  );
  const handleModalClose = useCallback(() => {
    if (error) {
      setError("");
      setShowConfirmation(false);
    } else {
      if (type === "bundle") {
        goToRoute("/bundles");
      } else if (type === "invoice") {
        goToRoute("/invoices");
      }
      handleConfirmationClose();
    }
  }, [
    goToRoute,
    handleConfirmationClose,
    error,
    setError,
    setShowConfirmation,
    type,
  ]);

  return (
    <Modal show={showConfirmation} onHide={handleModalClose} backdrop="static">
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body style={{ margin: 0, padding: 0 }}>
        <>
          <Card className="text-center" style={{ margin: 0, padding: 30 }}>
            <Card.Body>
              {loading && <Loading loadingText={loadingText} />}
              {!loading && error && (
                <>
                  <h2>Error</h2>
                  <Icon
                    iconName="Error"
                    style={{ fontSize: "4em", color: "red" }}
                  />
                  <p>{error}</p>
                </>
              )}
              {!loading && success && (
                <>
                  <h2>Success</h2>
                  <Icon
                    iconName="SkypeCircleCheck"
                    style={{ fontSize: "4em", color: "#009900" }}
                  />
                  <p>{successText}</p>
                </>
              )}
              {!loading && success && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    flex: "1 1 0px",
                  }}
                >
                  {type === "bundle" && (
                    <Button
                      variant="primary"
                      size="sm"
                      onClick={() => {
                        goToRoute("/invoices/add");
                        handleConfirmationClose();
                      }}
                      style={{ margin: 5, flex: "1 1 0px" }}
                    >
                      Attach an invoice
                    </Button>
                    /* <Button
                      variant="primary"
                      size="sm"
                      onClick={() => {
                        goToRoute("/bundles/add");
                        handleConfirmationClose();
                      }}
                      style={{ margin: 5, flex: "1 1 0px" }}
                    >
                      Add another bundle
                    </Button> */
                  )}
                </div>
              )}
            </Card.Body>
          </Card>
        </>
      </Modal.Body>
      {/* <Modal.Footer>
        <Button
          variant="secondary"
          onClick={() => {
            goToRoute("/bundles");
            handleConfirmationClose();
          }}
        >
          Close
        </Button>
      </Modal.Footer> */}
    </Modal>
  );
};
