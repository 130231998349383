import React from "react";
import { Icon } from "@fluentui/react/lib/Icon";

function Error({ errorText }: any) {
  return (
    <div className="error">
      <Icon iconName="Error" style={{fontSize: "3.5em", color:"red"}} />
      <h6>{errorText}</h6>
    </div>
  );
}

export default Error;
