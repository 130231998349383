import React from "react";
import { Spinner } from "react-bootstrap";

function Loading({ loadingText }: any) {
  return (
    <>
      <div className="loading">
        <Spinner
          animation="border"
          role="status"
          style={{ padding: "15px", margin: "15px" }}
        >
          <span className="visually-hidden">Loading...</span>
        </Spinner>
        <h6>{loadingText}</h6>
      </div>
    </>
  );
}

export default Loading;
