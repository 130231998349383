import React, { useCallback, useEffect, useState } from "react";
import { Bundle } from "../../interfaces/bundle";
import { getBundlesWithOrgs } from "../../services/bundlesService";
// import Button from "react-bootstrap/Button";
import { Form } from "react-bootstrap";
import Loading from "../Shared/Loading";
import Error from "../Shared/Error";
import { userStillAuthorised } from "../../services/auth";
import { OrgSelect } from "../Shared/OrgSelect";
import { useStore } from "../../store/createStore";
import { BundlesTable } from "./BundlesTable";

export const GetBundles = ({
  type,
  title,
  attachedBundleIds,
  setAttachedBundleIds,
  attachedBundles,
  setAttachedBundles,
}: any) => {
  const ORGANISATIONS = useStore((state) => state.organisations);
  const handleOnIdle = useStore((state) => state.handleOnIdle);
  const [bundles, setBundles] = useState<Bundle[]>([]);
  // const [limit, setLimit] = useState<number>();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>("");
  const [orgChoice, setOrgChoice] = useState<string[]>([]);
  const [searchAttempted, setSearchAttempted] = useState(false);

  const get = useCallback(
    async () => {
      setLoading(true);
      if (ORGANISATIONS && ORGANISATIONS.length) {
        const resp = await getBundlesWithOrgs(orgChoice);
        setSearchAttempted(true);
        if (resp.error) {
          setBundles([]);
          setError("Error retrieving bundles");
          setLoading(false);
          if (!userStillAuthorised(resp)) {
            console.log("handling logout");
            //logout
            handleOnIdle();
          }
          return;
        }
        const bundlesWithOrgs = resp.map((bundle: any) => {
          console.log("bundle: ", bundle);
          const bundleOrganisations = bundle.organisationIds
            .split(", ")
            .map(
              (orgId: any) =>
                ORGANISATIONS.filter((org) => org.organisationId === orgId)[0]
            );
          console.log("bundleOrganisations: ", bundleOrganisations);
          return {
            ...bundle,
            bundleOrganisations,
          };
        });
        setBundles(bundlesWithOrgs);
        setError("");
        setLoading(false);
      }
    },
    [/*limit,*/ orgChoice, setLoading, handleOnIdle, ORGANISATIONS]
  );

  useEffect(() => {
    get();
  }, [get]);

  const handleOrgSelect = useCallback(
    (e) => {
      setOrgChoice(e.map((organisation: any) => organisation.value));
    },
    [setOrgChoice]
  );

  return (
    <div>
      <h2>{title}</h2>
      <Form>
        {type === "attachToInvoice" && (
          <>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <div>
                Attached Bundle Names:{" "}
                {attachedBundles.length > 0
                  ? attachedBundles
                      .map((x: any) => String(x.bundleDescription))
                      .join(",  ")
                  : "No Bundles Attached"}
              </div>
            </Form.Group>
          </>
        )}

        <OrgSelect
          onChange={handleOrgSelect}
          handleOnIdle={handleOnIdle}
          required={true}
        />

        {/* <Button onClick={get} disabled={!ORGANISATIONS.length || loading}>
          Search
        </Button> */}
      </Form>

      {bundles.length === 0 && searchAttempted === true && !error && !loading && (
        <div className="loading">
          <h5 className="mb3">No Bundles Found</h5>
        </div>
      )}

      {bundles && bundles.length > 0 && !loading && !error && (
        <div>
          <BundlesTable
            bundles={bundles}
            type={type}
            setAttachedBundleIds={setAttachedBundleIds}
            attachedBundleIds={attachedBundleIds}
            attachedBundles={attachedBundles}
            setAttachedBundles={setAttachedBundles}
          />
        </div>
      )}

      {loading && <Loading loadingText="searching for bundles..." />}

      {error && !loading && <Error errorText={error} />}
    </div>
  );
};
