import React from "react";
import { useStore } from "../../store/createStore";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import TextField from "@mui/material/TextField";
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers";

export const PeriodSelect = () => {
  const periodStart = useStore((state) => state.billingPeriodStart);
  const periodEnd = useStore((state) => state.billingPeriodEnd);
  const setPeriodStart = useStore((state) => state.setBillingPeriodStart);
  const setPeriodEnd = useStore((state) => state.setBillingPeriodEnd);

  const onPeriodStartChange = (e: any) => {
    setPeriodStart(e.startOf("month").format("YYYY-MM-DD"));
  };

  const onPeriodEndChange = (e: any) => {
    setPeriodEnd(e.startOf("month").format("YYYY-MM-DD"));
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <div className="row m-3">
        <div className="col">
          <DatePicker
            views={["year", "month"]}
            label="Period Start"
            minDate={dayjs("2021-01-01")}
            maxDate={dayjs("2023-12-31")}
            value={periodStart}
            onChange={(newValue) => {
              onPeriodStartChange(newValue);
            }}
            renderInput={(params) => (
              <TextField {...params} helperText={null} />
            )}
          />
        </div>
        <div className="col">
          <DatePicker
            views={["year", "month"]}
            label="Period End"
            minDate={dayjs("2021-01-01")}
            maxDate={dayjs("2023-12-31")}
            value={periodEnd}
            onChange={(newValue) => {
              onPeriodEndChange(newValue);
            }}
            renderInput={(params) => (
              <TextField {...params} helperText={null} />
            )}
          />
        </div>
      </div>
    </LocalizationProvider>
  );
};
