import { UKDateStringToDate } from "./utils";

export const alertStylingFn = (bool: boolean) => {
  if (bool) {
    return {
      // color: "red",
      // border: "1.5px solid red",
    };
  }
};

export const alertRowStyling = (
  expiryDate: string,
  remainingBalance: Number
) => {
  if (remainingBalance > 0) {
    // if (expiryDate && UKDateStringToDate(expiryDate) >= new Date() && remainingBalance > 0) {
    return "";
  } else {
    return "table-danger";
  }
};

export const alertCellStyling = (cell: any) => {
  if (cell.column.Header === "Bundle Expiry") {
    return alertStylingFn(
      UKDateStringToDate(cell.value) < new Date() || cell.value === "N/A"
    );
  }
  if (cell.column.Header === "Remaining Balance/Overage") {
    return alertStylingFn(cell.value <= 0);
  }

  return alertStylingFn(false);
};
