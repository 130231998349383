import React, { useMemo } from "react";
import { Table } from "react-bootstrap";
import {
  Column,
  useFilters,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import {
  dateSort,
  //   dateSort,
  DefaultColumnFilter,
  //   filterExpiry,
  // filterGreaterThan,
  //   SelectExpiredColumnFilter,
  // SliderColumnFilter,
} from "../Shared/TableFilters";
import { FilterIcon } from "../Shared/FilterIcon";
import { Icon } from "@fluentui/react";
import { PaginationInput } from "../Shared/PaginationInput";
import { BundleUsage } from "../../interfaces/bundleUsage";

export const UsageTable = ({ usageHistory }: any) => {
  const data: Array<any> = useMemo(() => {
    return usageHistory.map((usage: BundleUsage) => {
      return {
        periodStart: new Date(usage.periodStart).toLocaleDateString(),
        periodEnd: new Date(usage.periodEnd).toLocaleDateString(),
        broughtForward:
          Number(usage.remainingBalance) +
          Number(usage.usageIn) +
          Number(usage.usageOut),
        usageIn: usage.usageIn,
        usageOut: usage.usageOut,
        remaining: usage.remainingBalance,
        // bundleDescription: usage.bundleDescription,
        bundleId: usage.bundleId,
      };
    });
  }, [usageHistory]);

  const columns: Array<Column> = useMemo(
    () => [
      {
        Header: "Bundle ID",
        accessor: "bundleId", // accessor is the "key" in the data
        canFilter: false,
        disableFilters: true,
      },
      // {
      //   Header: "Bundle Description",
      //   accessor: "bundleDescription", // accessor is the "key" in the data
      //   Filter: DefaultColumnFilter,
      //   // canFilter: false,
      //   // disableFilters: true,
      // },
      {
        Header: "Period Start",
        accessor: "periodStart", // accessor is the "key" in the data
        canFilter: false,
        disableFilters: true,
        sortType: dateSort,
      },
      {
        Header: "Period End",
        accessor: "periodEnd", // accessor is the "key" in the data
        canFilter: false,
        disableFilters: true,
        sortType: dateSort,
      },
      {
        Header: "Brought Forward",
        accessor: "broughtForward",
        // Filter: SliderColumnFilter,
        // filter: filterGreaterThan,
        canFilter: false,
        disableFilters: true,
      },
      {
        Header: "Usage In",
        accessor: "usageIn",
        // Filter: SliderColumnFilter,
        // filter: filterGreaterThan,
        canFilter: false,
        disableFilters: true,
      },
      {
        Header: "Usage Out",
        accessor: "usageOut",
        // Filter: SliderColumnFilter,
        // filter: filterGreaterThan,
        canFilter: false,
        disableFilters: true,
      },
      {
        Header: "Remaining Balance",
        accessor: "remaining",
        // Filter: SliderColumnFilter,
        // filter: filterGreaterThan,
        canFilter: false,
        disableFilters: true,
      },
    ],
    []
  );

  const defaultColumn: any = React.useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter,
    }),
    []
  );

  const tableInstance: any = useTable(
    { columns, data, defaultColumn },
    useFilters,
    useSortBy,
    usePagination
  );
  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, page } =
    tableInstance;

  return (
    <div>
      <Table
        striped
        bordered
        variant="light"
        responsive
        className="table-hover"
        {...getTableProps()}
      >
        <thead>
          {
            // Loop over the header rows
            headerGroups.map((headerGroup: any, i: any) => {
              // Apply the header row props
              return (
                <tr {...headerGroup.getHeaderGroupProps()} key={i}>
                  {
                    // Loop over the headers in each row
                    headerGroup.headers.map((column: any) => (
                      // Apply the header cell props
                      <th
                        style={{
                          minWidth: "5vw",
                        }}
                        key={column.id}
                      >
                        {/* Add a sort direction indicator */}
                        <span
                          {...column.getHeaderProps(
                            column.getSortByToggleProps()
                          )}
                        >
                          {column.isSorted ? (
                            column.isSortedDesc ? (
                              <Icon className="m-1" iconName="SortDown" />
                            ) : (
                              <Icon className="m-1" iconName="SortUp" />
                            )
                          ) : // <Icon className="m-1" iconName="Sort" />
                          null}
                          {
                            // Render the header
                            column.render("Header")
                          }
                        </span>
                        <span>
                          {/* {column.canFilter ? column.render("Filter") : null} */}
                          {column.canFilter ? (
                            <FilterIcon column={column} />
                          ) : null}
                        </span>
                      </th>
                    ))
                  }
                </tr>
              );
            })
          }
        </thead>
        <tbody {...getTableBodyProps()}>
          {!!data.length &&
            // Loop over the table rows
            page.map((row: any) => {
              // Prepare the row for display
              prepareRow(row);
              return (
                // Apply the row props
                <tr {...row.getRowProps()} key={row.id}>
                  {
                    // Loop over the rows cells
                    row.cells.map((cell: any) => {
                      // Apply the cell props
                      return (
                        <td
                          {...cell.getCellProps()}
                          key={`${cell.row.id} - ${cell.column.id}`}
                        >
                          {
                            // Render the cell contents
                            cell.render("Cell")
                          }
                        </td>
                      );
                    })
                  }
                </tr>
              );
            })}
          {!data.length && <tr>No usage data</tr>}
        </tbody>
      </Table>
      <PaginationInput tableInstance={tableInstance} />
    </div>
  );
};
