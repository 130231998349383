export const UKDateStringToDate = (dateString: string) => {
  const splitDateString = dateString.split("/");
  return new Date(
    Number(splitDateString[2]),
    Number(splitDateString[1]) - 1,
    Number(splitDateString[0])
  );
};

export const isValidDate = (date: any): boolean => {
  return date instanceof Date && !isNaN(Number(date));
};
