import { getWrapper } from "./auth";
const backendEndpoint = process.env.REACT_APP_BACKEND_ENPOINT || "";

export const getBundleUsage = async (bundleId: number) => {
  try {
    const resp = await getWrapper(
      `${backendEndpoint}/usage/bundle/${bundleId}`,
      {}
    );
    console.log(resp);
    if (resp.error) return resp;
    return resp;
  } catch (e: any) {
    console.log(e);
    return { error: e.message };
  }
};

export const getBundleUsageHistory = async (bundleId: number) => {
  try {
    const resp = await getWrapper(
      `${backendEndpoint}/usage/history/bundle/${bundleId}`,
      {}
    );
    console.log(resp);
    if (resp.error) return resp;
    return resp;
  } catch (e: any) {
    console.log(e);
    return { error: e.message };
  }
};
