import moment from "moment";
import create from "zustand";
import { Bundle } from "../interfaces/bundle";

export interface OrganisationState {
  organisationId: string;
  name: string;
  type: string;
  countryCode: string;
  timeZone: string;
  periodStart: string;
  periodEnd: string;
  totalSMSAdded: number;
  broughtForward: number;
  totalMessagesIn: number;
  totalMessagesOut: number;
  expired: number;
  balance: number;
  licenseExpiry: string;
  overallRemainingBalance: number;
  overallExpiry: string;
  recentUsageAverage: number;
  addedMessages: number;
  bundles: Partial<BundleState>[];
}

interface BundleUsageState {}

export interface BundleState extends Bundle {
  usageHistory?: BundleUsageState[];
}

interface OrganisationBundleState {
  organisationId: string;
  bundles: BundleState[];
}

interface state {
  organisations: OrganisationState[];
  setOrganisations: (orgs: OrganisationState[]) => void;
  resetOrganisations: () => void;
  billingPeriodStart: string;
  billingPeriodEnd: string;
  setBillingPeriodStart: (billingPeriodStart: string) => void;
  setBillingPeriodEnd: (billingPeriodEnd: string) => void;
  resetBillingPeriod: () => void;
  bundles: BundleState[];
  setBundles: (bundles: BundleState[]) => void;
  resetBundles: () => void;
  organisationBundles: OrganisationBundleState[];
  setOrganisationBundles: (orgBundles: OrganisationBundleState[]) => void;
  resetOrganisationbundles: () => void;
  user: any;
  userIdle: Boolean;
  authError: Boolean;
  setAuthError: (bool: Boolean) => void;
  setCurrentUser: (user: any) => void;
  setUserIdle: (bool: Boolean) => void;
  handleOnIdle: () => void;
}

const firstDayOfMonth = moment().startOf("month").format("YYYY-MM-DD");
const lastDayofMonth = moment().endOf("month").format("YYYY-MM-DD");

export const useStore = create<state>((set, get) => ({
  // Core State

  // organisation state
  organisations: [],
  setOrganisations: (orgs: OrganisationState[]) =>
    set((state) => ({ ...state, organisations: orgs })),
  resetOrganisations: () => set((state) => ({ ...state, organisations: [] })),

  // billing period state
  billingPeriodStart: firstDayOfMonth,
  billingPeriodEnd: lastDayofMonth,
  setBillingPeriodStart: (billingPeriodStart: string) =>
    set((state) => ({ ...state, billingPeriodStart })),
  setBillingPeriodEnd: (billingPeriodEnd: string) =>
    set((state) => ({ ...state, billingPeriodEnd })),
  resetBillingPeriod: () => {
    set((state) => ({
      ...state,
      billingPeriodStart: firstDayOfMonth,
      billingPeriodEnd: lastDayofMonth,
    }));
  },

  // bundle state
  bundles: [],
  setBundles: (bundles: BundleState[]) =>
    set((state) => ({ ...state, bundles: [...bundles] })),
  resetBundles: () => set((state) => ({ ...state, bundles: [] })),

  // organisationbundle state
  organisationBundles: [],
  setOrganisationBundles: (orgBundles: OrganisationBundleState[]) =>
    set((state) => ({ ...state, organisationBundles: [...orgBundles] })),
  resetOrganisationbundles: () => set((state) => ({ ...state, bundles: [] })),

  // User State
  user: "",
  userIdle: false,
  authError: false,
  setAuthError: (bool: Boolean) =>
    set((state) => ({ ...state, authError: bool })),
  setCurrentUser: (user: any) => set((state) => ({ ...state, user })),
  setUserIdle: (bool: Boolean) =>
    set((state) => ({ ...state, userIdle: bool })),
  handleOnIdle: () => {
    localStorage.removeItem("user");
    localStorage.setItem("user", JSON.stringify({ isActive: false }));
    set((state) => ({
      ...state,
      user: "",
      userIdle: true,
    }));
  },
}));
