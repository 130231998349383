import React, { useCallback, useState } from "react";
import { Form, Row, Col, Button, Modal } from "react-bootstrap";
import { GetBundles } from "../Bundle/GetBundles";
import { Invoice, License } from "../../interfaces/invoice";
import { addInvoice } from "../../services/invoiceService";
import { Bundle } from "../../interfaces/bundle";
import { userStillAuthorised } from "../../services/auth";
import { Confirmation } from "../Shared/Confirmation";
import { useStore } from "../../store/createStore";
import moment from "moment";

const now = new Date();
const nextYear = moment(now).add(1, "years").subtract(1, "days").toDate();

export const AddInvoice = () => {
  const handleOnIdle = useStore((state) => state.handleOnIdle);
  const [invoice, setInvoice] = useState<any>();
  const [license, setLicense] = useState<License>({
    periodStart: now.toISOString().split("T")[0],
    expiryDate: nextYear.toISOString().split("T")[0],
  });
  const [loading, setLoading] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);
  const [showBundleSearch, setShowBundleSearch] = useState(false);
  const [error, setError] = useState<string>("");
  const [showConfirmation, setShowConfirmation] = useState<boolean>(false);

  const [attachedBundleIds, setAttachedBundleIds] = useState<number[]>([]);
  const [attachedBundles, setAttachedBundles] = useState<Bundle[]>([]);
  const [includeLicense, setIncludeLicense] = useState<Boolean>(false);

  const handleModalClose = () => {
    // clear on close?
    // setAttachedBundleIds([]);
    // setAttachedBundles([]);
    setShowBundleSearch(false);
  };

  const handleModalShow = () => {
    // setAttachedBundleIds([]); // Clear before each search?
    // setAttachedBundles([]);
    setShowBundleSearch(true);
  };

  const handleSaveBundleChoice = () => {
    updateInvoice({ bundleIds: attachedBundleIds });
    setShowBundleSearch(false);
  };

  const updateInvoice = useCallback(
    (obj: Partial<Invoice>) => {
      setInvoice({ ...invoice, ...obj });
    },
    [invoice]
  );

  const updateLicense = useCallback(
    (obj: Partial<License>) => {
      setLicense({ ...license, ...obj });
    },
    [license]
  );

  const successAnimation = useCallback(() => {
    setLoading(false);
    setSuccess(true);
    setError("");
  }, []);

  const handleConfirmationClose = useCallback(() => {
    setInvoice({});
    setShowConfirmation(false);
    setSuccess(false);
  }, [setInvoice, setShowConfirmation, setSuccess]);

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      setShowConfirmation(true);
      setLoading(true);
      if (!invoice.bundleIds || !invoice.bundleIds.length) {
        setError("Please select at least one bundle");
        setLoading(false);
        return;
      }
      console.log("Send Invoice details to backend API: ", {
        ...invoice,
        license,
      });
      const invoiceRequest = includeLicense ? { ...invoice, license } : invoice;
      const resp = await addInvoice(invoiceRequest);
      console.log("resp:", resp);
      if (resp.error) {
        setError(resp.error);
        setLoading(false);
        if (!userStillAuthorised(resp)) {
          console.log("handling logout");
          //logout
          handleOnIdle();
        }
        return;
      }
      // setBackendResp(resp);
      successAnimation();
    },
    [invoice, successAnimation, handleOnIdle, license, includeLicense]
  );

  return (
    <div className="bundles">
      <h2>Add Invoice:</h2>

      <Form onSubmit={handleSubmit}>
        <Form.Group className="mb-3" controlId="formInvoiceDescription">
          <Form.Label>Invoice Description: </Form.Label>
          <Form.Control
            type="text"
            value={invoice?.invoiceDescription}
            onChange={(e) =>
              updateInvoice({ invoiceDescription: e.target.value })
            }
            required={true}
            placeholder="Invoice Description"
          />
        </Form.Group>
        <Row>
          <Col>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Invoice Number: </Form.Label>
              <Form.Control
                type="text"
                value={invoice?.invoiceNumber}
                onChange={(e) =>
                  updateInvoice({ invoiceNumber: e.target.value })
                }
                required={true}
                placeholder="Invoice Number"
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Purchase Order Number: </Form.Label>
              <Form.Control
                type="text"
                value={invoice?.purchaseOrderNumber}
                onChange={(e) =>
                  updateInvoice({ purchaseOrderNumber: e.target.value })
                }
                required={true}
                placeholder="Purchase Order Number"
              />
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Value: </Form.Label>
              <Form.Control
                type="number"
                value={invoice?.value}
                onChange={(e) =>
                  updateInvoice({ value: Number(e.target.value) })
                }
                required={true}
                placeholder="Value"
                min="1"
                max="1000000"
              />
            </Form.Group>
          </Col>

          <Col>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Currency: </Form.Label>
              <Form.Select
                required={true}
                placeholder="Currency"
                value={invoice?.currency}
                defaultValue=""
                onChange={(e) =>
                  updateInvoice({
                    currency: (e.target as HTMLInputElement).value,
                  })
                }
              >
                <option value="" disabled hidden>
                  Please Select Currency
                </option>
                <option value="GBP">GBP</option>
                <option value="USD">USD</option>
                <option value="AUD">AUD</option>
              </Form.Select>
            </Form.Group>
          </Col>
        </Row>

        <Form.Group className="mb-3" controlId="formBasicEmail">
          {/* <h4>Bundles:</h4> */}
          <div>
            {/* <b>Attached Bundle Names:</b>{" "} */}
            {attachedBundles.length > 0
              ? attachedBundles.map((x: any) => (
                  <div key={x.bundleId}>{String(x.bundleDescription)}</div>
                ))
              : "No bundles"}
          </div>
        </Form.Group>
        <Form.Group className="mb-3" controlId="formBasicEmail">
          <Button variant="secondary" onClick={handleModalShow}>
            + Add Bundles
          </Button>

          <Modal
            show={showBundleSearch}
            onHide={handleModalClose}
            size="xl"
            backdrop="static"
            style={{
              textAlign: "center",
            }}
          >
            {/* <Modal.Header closeButton>
              <Modal.Title>Attach Invoice to Bundles</Modal.Title>
            </Modal.Header> */}
            <Modal.Body>
              <GetBundles
                handleOnIdle={handleOnIdle}
                title="Attach Bundles"
                type="attachToInvoice"
                attachedBundleIds={attachedBundleIds}
                setAttachedBundleIds={setAttachedBundleIds}
                attachedBundles={attachedBundles}
                setAttachedBundles={setAttachedBundles}
              />
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleModalClose}>
                Close
              </Button>
              <Button variant="primary" onClick={handleSaveBundleChoice}>
                Save Changes
              </Button>
            </Modal.Footer>
          </Modal>
        </Form.Group>
        <Form.Group className="mb-3">
          {/* <h4>License:</h4> */}
          {!includeLicense && (
            <>
              <div className="mb-3">No license</div>
              <Button
                variant="secondary"
                onClick={() => {
                  setIncludeLicense(true);
                }}
              >
                + Add License
              </Button>
            </>
          )}
          {includeLicense && (
            <>
              <div>License:</div>
              <Row>
                <Col>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Start Date: </Form.Label>
                    <Form.Control
                      type="date"
                      value={license?.periodStart}
                      onChange={(e) =>
                        updateLicense({
                          periodStart: new Date(e.target.value)
                            .toISOString()
                            .split("T")[0],
                          expiryDate: moment(new Date(e.target.value))
                            .add(1, "years")
                            .subtract(1, "days")
                            .toDate()
                            .toISOString()
                            .split("T")[0],
                        })
                      }
                      required={true}
                      placeholder="Start Date"
                      //placeholder={(new Date()).toString()}
                    />
                  </Form.Group>
                </Col>

                <Col>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label> Expiry Date: </Form.Label>
                    <Form.Control
                      type="date"
                      value={license?.expiryDate}
                      onChange={(e) =>
                        updateLicense({ expiryDate: e.target.value })
                      }
                      required={true}
                      placeholder="Expiry"
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Button
                variant="danger"
                onClick={() => {
                  setIncludeLicense(false);
                }}
              >
                - Remove License
              </Button>
            </>
          )}
        </Form.Group>

        <Confirmation
          showConfirmation={showConfirmation}
          setShowConfirmation={setShowConfirmation}
          handleConfirmationClose={handleConfirmationClose}
          loading={loading}
          loadingText="Adding Invoice..."
          success={success}
          successText="Invoice added successfully"
          error={error}
          setError={setError}
          type="invoice"
        />

        {!loading && !success && (
          <Button variant="primary" type="submit" className="mt-3">
            Submit
          </Button>
        )}
      </Form>
    </div>
  );
};
