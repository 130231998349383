import React from "react";
import { useMsal } from "@azure/msal-react";
import { handleLogout } from "../services/auth";
import { Nav } from "react-bootstrap";
import { useStore } from "../store/createStore";

/**
 * Renders a button which, when selected, will open a popup for logout
 */
export const SignOutButton = () => {
  const { instance } = useMsal();
  const setCurrentUser = useStore((state) => state.setCurrentUser);
  return (
    <Nav.Link
      style={{ color: "red" }}
      onClick={() => handleLogout(instance, setCurrentUser, true)}
    >
      Sign Out
    </Nav.Link>
  );
};
