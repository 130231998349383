import { Invoice } from "../interfaces/invoice";
import { getWrapper, postWrapper } from "./auth";
const backendEndpoint = process.env.REACT_APP_BACKEND_ENPOINT || "";

export const addInvoice = async (invoice: Partial<Invoice>) => {
  try {
    return await postWrapper(`${backendEndpoint}/invoices`, invoice);
  } catch (e: any) {
    console.log(e);
    return { error: e.message };
  }
};

export const getInvoices = async (orgChoice: string[], purchaseOrderNumber?: string,
  invoiceNumber?: string, limit?: number) => {
  try {
    const reducer = (previousValue: any, currentValue: any) =>
      String(previousValue) + "|" + String(currentValue);
    const url = limit
      ? `${backendEndpoint}/invoicesWithBundlesAndOrgs?limit=${limit}`
      : `${backendEndpoint}/invoicesWithBundlesAndOrgs`;
    // const params = orgChoice.length
    //   ? { orgChoiceString: orgChoice.reduce(reducer) }
    //   : {};
    const params: any = {}
    if (orgChoice.length) {
      params.orgChoiceString = orgChoice.reduce(reducer)
    }
    if (purchaseOrderNumber) {
      params.purchaseOrderNumber = purchaseOrderNumber
    }
    if (invoiceNumber) {
      params.invoiceNumber = invoiceNumber
    }
    const resp = await getWrapper(url, {
      params,
      // paramsSerializer: params => {
      //   return qs.stringify(params)
      // }
    });
    if (resp.error) return resp;
    return resp;
  } catch (e: any) {
    console.log(e);
    return { error: e.message };
  }
};
