import React, { useCallback, useRef, useState } from "react";
import { Button, Form, Row, Col } from "react-bootstrap";
import { Bundle } from "../../interfaces/bundle";
import { addBundle } from "../../services/bundlesService";
// import Select from "react-select";
import { OrgSelect } from "../Shared/OrgSelect";
import { userStillAuthorised } from "../../services/auth";
import { Confirmation } from "../Shared/Confirmation";
import moment from "moment";
import { useStore } from "../../store/createStore";

const now = new Date();
const nextYear = moment(now).add(1, "years").subtract(1, "days").toDate();

export const AddBundle = () => {
  const handleOnIdle = useStore((state) => state.handleOnIdle);
  const [bundle, setBundle] = useState<Partial<Bundle>>({
    startDate: now.toISOString().split("T")[0],
    expiryDate: nextYear.toISOString().split("T")[0],
  });
  // const [backendResp, setBackendResp] = useState("");
  // const [bundleValid, setBundleValid] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [showConfirmation, setShowConfirmation] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);
  const [error, setError] = useState<string>("");
  const bundleFormRef = useRef<HTMLFormElement>(null);

  // const [error, setError] = useState<string>("");
  // const [ORGANISATIONS, setORGANISATIONS] = useState<any[]>([]);

  // const isValidBundle = useCallback((): boolean => {
  //   return Boolean(
  //     bundleFormRef.current && bundleFormRef.current.checkValidity()
  //   );
  // }, [bundleFormRef]);

  const successAnimation = useCallback(() => {
    setLoading(false);
    setSuccess(true);
    setError("");
  }, [setLoading, setSuccess, setError]);

  const updateBundle = useCallback(
    (obj: Partial<Bundle>) => {
      setBundle({ ...bundle, ...obj });
    },
    [bundle]
  );

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      setShowConfirmation(true);
      setLoading(true);
      const resp = await addBundle(bundle);
      console.log("resp:", resp);
      if (resp.error) {
        setError(resp.error);
        setLoading(false);
        if (!userStillAuthorised(resp)) {
          console.log("handling logout");
          //logout
          handleOnIdle();
        }
        return;
      }
      // setBackendResp(resp);
      successAnimation();
    },
    [bundle, successAnimation, handleOnIdle]
  );

  const handleConfirmationClose = useCallback(() => {
    setBundle({});
    setShowConfirmation(false);
    setSuccess(false);
  }, [setBundle, setShowConfirmation, setSuccess]);

  const handleOrgSelect = useCallback(
    (e) => {
      updateBundle({
        organisationIds: e.map((organisation: any) => organisation.value),
      });
    },
    [updateBundle]
  );

  return (
    <div className="bundles">
      <h2>Add Bundle:</h2>

      <Form onSubmit={handleSubmit} ref={bundleFormRef}>
        <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Label>Bundle Description: </Form.Label>
          <Form.Control
            type="text"
            value={bundle.bundleDescription}
            onChange={(e) =>
              updateBundle({ bundleDescription: e.target.value })
            }
            required={true}
            placeholder="Bundle Description"
            pattern="^[A-z0-9 ]{1,255}$"
          />
        </Form.Group>
        <OrgSelect onChange={handleOrgSelect} />
        <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Label>Number of SMS: </Form.Label>
          <Form.Control
            type="number"
            value={bundle?.initialBalance}
            onChange={(e) => updateBundle({ initialBalance: Number(e.target.value) })}
            required={true}
            placeholder="Number of SMS"
            min="1"
            max="1000000"
          />
        </Form.Group>

        <Row>
          <Col>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Select Start Date: </Form.Label>
              <Form.Control
                type="date"
                value={bundle?.startDate}
                onChange={(e) =>
                  updateBundle({
                    startDate: new Date(e.target.value)
                      .toISOString()
                      .split("T")[0],
                    expiryDate: moment(new Date(e.target.value))
                      .add(1, "years")
                      .subtract(1, "days")
                      .toDate()
                      .toISOString()
                      .split("T")[0],
                  })
                }
                required={true}
                placeholder="Start Date"
                //placeholder={(new Date()).toString()}
              />
            </Form.Group>
          </Col>

          <Col>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Select Expiry Date: </Form.Label>
              <Form.Control
                type="date"
                value={bundle?.expiryDate}
                onChange={(e) => updateBundle({ expiryDate: e.target.value })}
                required={true}
                placeholder="Expiry"
              />
            </Form.Group>
          </Col>
        </Row>
        <Confirmation
          showConfirmation={showConfirmation}
          setShowConfirmation={setShowConfirmation}
          handleConfirmationClose={handleConfirmationClose}
          loading={loading}
          loadingText="Adding Bundle..."
          success={success}
          successText="Bundle added successfully"
          error={error}
          setError={setError}
          type="bundle"
        />

        {!loading && !success && (
          <Button variant="primary" type="submit">
            Submit
          </Button>
        )}
      </Form>
    </div>
  );
};
