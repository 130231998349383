import React from "react";
import logo from "../logo.png";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { loginRequest } from "./authConfig";
import Button from "react-bootstrap/Button";
import { Icon } from "@fluentui/react/lib/Icon";
import { IPublicClientApplication } from "@azure/msal-browser";
import { useStore } from "../store/createStore";

async function handleMicrosoftLogin(instance: IPublicClientApplication) {
  try {
    console.log("awaiting login popup");
    await instance.loginRedirect(loginRequest);
    console.log("finished login popup");
    return;
  } catch (e: any) {
    console.log(e.message);
    if (e.message.includes("interaction_in_progress")) {
      return alert("Please check you have all microsoft login windows closed");
    }
    console.log("try popup instead");
    try {
      await instance.loginPopup(loginRequest);
    } catch (e: any) {
      console.log(e.message);
      if (e.message.includes("interaction_in_progress")) {
        return alert(
          "Please check you have all microsoft login windows closed"
        );
      }
    }
  }
}

function SignIn() {
  const { instance } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const setCurrentUser = useStore((state) => state.setCurrentUser);

  const GetToken = async () => {
    try {
      if (!isAuthenticated) {
        await handleMicrosoftLogin(instance);
      }
    } catch (e: any) {
      // If fails try pop up
      console.log(e);
      return {};
    }
  };

  return (
    <div className="sign-in">
      <img
        src={logo}
        alt="florence logo"
        height="100px"
        className="d-inline-block align-top"
        // style={{ filter: "brightness(0) invert(1)" }}
      />
      <h1
      style={{fontWeight: "bold"}}
      >
        Bundles & Billing
      </h1>
      <Button
        variant="outline-secondary"
        className="ml-auto"
        onClick={async () => {
          const user = await GetToken();
          setCurrentUser(user);
        }}
      >
        <Icon iconName="WindowsLogo" style={{ marginRight: "8px" }} />
        Sign In
      </Button>
    </div>
  );
}

export default SignIn;
