import axios from "axios";
const backendEndpoint = process.env.REACT_APP_BACKEND_ENPOINT || "";

const handleAxiosError = (error: any) => {
  if (error.response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    console.log("error.response.data", error.response.data);
    console.log("error.response.status", error.response.status);
    console.log("error.response.headers", error.response.headers);
    console.log("error.message", error.message);
    return {
      error: error.message,
      errorStatus: error.response.status,
    };
  } else if (error.request) {
    // The request was made but no response was received
    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
    // http.ClientRequest in node.js
    console.log("error.request", error.request);
    return {
      error: error.message,
      errorStatus: 500,
    };
  } else {
    // Something happened in setting up the request that triggered an Error
    console.log("Error", error.message);
    return {
      error: error.message,
      errorStatus: 500,
    };
  }
};

export const backendAuthentication = async (token: string) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  const body = {
    accessToken: token,
  };
  try {
    const resp = await axios.post(`${backendEndpoint}/login`, body, config);
    return resp.data;
  } catch (e: any) {
    return handleAxiosError(e);
  }
};

export const inviteUser = async (email: string) => {
  const payload = {
    email,
  };
  try {
    return await postWrapper(`${backendEndpoint}/invite`, payload);
  } catch (e: any) {
    return handleAxiosError(e);
  }
};

export const getWrapper = async (endpoint: string, options: any = {}) => {
  // options arg cannot be use to specify additional headers currently
  try {
    const user = JSON.parse(localStorage.getItem("user") || "");
    const token = user ? user.authorizationToken : "";
    const config = Object.assign(
      {
        headers: {
          Authorization: token,
        },
      },
      options
    );
    const resp = await axios.get(endpoint, config);
    return resp.data;
  } catch (e: any) {
    return handleAxiosError(e);
  }
};

export const postWrapper = async (endpoint: string, payload: any) => {
  try {
    const user = JSON.parse(localStorage.getItem("user") || "");
    const token = user ? user.authorizationToken : "";
    const config = {
      headers: {
        Authorization: token,
        "Content-Type": "application/json",
      },
    };
    const resp = await axios.post(endpoint, payload, config);
    console.log(resp.data);
    return resp.data;
  } catch (e: any) {
    return handleAxiosError(e);
  }
};

export const patchWrapper = async (endpoint: string, payload: any) => {
  try {
    const user = JSON.parse(localStorage.getItem("user") || "");
    const token = user ? user.authorizationToken : "";
    const config = {
      headers: {
        Authorization: token,
        "Content-Type": "application/json",
      },
    };
    const resp = await axios.patch(endpoint, payload, config);
    console.log(resp.data);
    return resp.data;
  } catch (e: any) {
    return handleAxiosError(e);
  }
};

// Redirect on page
export function handleLogout(
  instance: any,
  setCurrentUser: any,
  logOutMS: boolean = false
) {
  localStorage.removeItem("user");
  setCurrentUser("");
  // force logout - TODO: confirm requirements
  if (logOutMS) {
    instance.logoutRedirect().catch((e: any) => {
      console.error(e);
      instance.logoutPopup().catch((e: any) => {
        console.error(e);
      });
    });
  }
}

export function userStillAuthorised(resp: any): boolean {
  if (resp.error) {
    if (resp.errorStatus === 401 || resp.errorStatus === 403) {
      console.log("user unauthorised, should log out");
      //logout
      return false;
    }
  }
  return true;
}
