import React, { useCallback, useEffect, useState } from "react";
import { useStore } from "../../store/createStore";
import Loading from "../Shared/Loading";
import { OrgSelect } from "../Shared/OrgSelect";
import { PeriodSelect } from "../Shared/PeriodSelect";
// import Error from "../Shared/Error";
import { OrganisationsTable } from "./OrganisationsTable";

export const GetOrganisations = () => {
  const ORGANISATIONS = useStore((state) => state.organisations);
  const [orgSearch, setOrgSearch] = useState(ORGANISATIONS);
  const [orgChoice, setOrgChoice] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  // const [error, setError] = useState<string>("");
  const handleOrgSelect = useCallback(
    (e) => {
      setOrgChoice(e.map((organisation: any) => organisation.value));
      console.log(orgChoice);
    },
    [setOrgChoice, orgChoice]
  );

  useEffect(() => {
    if (ORGANISATIONS.length) {
      if (orgChoice.length) {
        setOrgSearch(
          ORGANISATIONS.filter((org) => {
            return orgChoice.includes(org.organisationId);
          })
        );
      } else {
        setOrgSearch(ORGANISATIONS);
      }
      setLoading(false);
    } else {
      setLoading(true);
    }
  }, [orgChoice, ORGANISATIONS]);

  return (
    <div className="bundles">
      <h2>Organisations Dashboard</h2>
      <div className="mt-3">
        <PeriodSelect />
      </div>
      <OrgSelect onChange={handleOrgSelect} />
      {!!orgSearch.length && !loading && (
        <OrganisationsTable organisations={orgSearch} />
      )}
      {loading && <Loading loadingText="searching for organisations..." />}
      {/* {error && !loading && <Error errorText={error} />} */}
    </div>
  );
};
