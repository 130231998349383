import React from "react";
import { Container, Nav, Navbar, NavDropdown } from "react-bootstrap";
import { SignOutButton } from "../auth/SignOutButton";
import logo from "../logo.png";
import { LinkContainer } from "react-router-bootstrap";
import { useStore } from "../store/createStore";

export const TopNav = () => {
  const user = useStore((state) => state.user);
  return (
    <>
      <Navbar
        collapseOnSelect
        expand="lg"
        variant="light"
        // className="navbar navbar-expand-lg navbar-dark bg-primary"
        className="navbar navbar-expand-lg"
      >
        <Container>
          <Navbar.Brand>
            <img
              src={logo}
              alt="logo"
              height="60vw"
              className="d-inline-block align-top"
              // style={{ filter: "brightness(0) invert(1)" }}
            />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="me-auto">
              <LinkContainer to="/">
                <Nav.Link>Home</Nav.Link>
              </LinkContainer>
              <NavDropdown title="Bundles" id="bundle-dropdown">
                <NavDropdown.Item>
                  <LinkContainer to="/bundles">
                    <Nav.Link>Search</Nav.Link>
                  </LinkContainer>
                </NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item>
                  <LinkContainer to="/bundles/add">
                    <Nav.Link>Add</Nav.Link>
                  </LinkContainer>
                </NavDropdown.Item>
              </NavDropdown>
              {/* <LinkContainer to="/bundles">
                <Nav.Link>Bundles</Nav.Link>
              </LinkContainer> */}
              <NavDropdown title="Invoices" id="invoice-dropdown">
                <NavDropdown.Item>
                  <LinkContainer to="/invoices">
                    <Nav.Link>Search</Nav.Link>
                  </LinkContainer>
                </NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item>
                  <LinkContainer to="/invoices/add">
                    <Nav.Link>Add</Nav.Link>
                  </LinkContainer>
                </NavDropdown.Item>
              </NavDropdown>
            </Nav>
            <Nav>
              <NavDropdown title="Account" id="basic-nav-dropdown">
                <NavDropdown.Item>
                  <LinkContainer to="/invite">
                    <Nav.Link>Invite User</Nav.Link>
                  </LinkContainer>
                </NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item>
                  {user ? <SignOutButton /> : null}
                </NavDropdown.Item>
              </NavDropdown>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
};
